import { Divider, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import React, { useMemo } from 'react'
import { is } from 'src/util/is';
import { possiblePayoutRules } from './teams.constants';
import { useDispatch } from 'react-redux';
import { alertSlice } from 'src/store/alert/alert.slice';
import enums from 'src/util/enums';

const TeamFinanceForm = ({formik,permissions,paymentDetails}) => {
  const dispatch = useDispatch();
  const {
    actions: { setAlert },
  } = alertSlice;  
    const {
        errors,
        values,
        touched,
        getFieldProps,
        setFieldValue,
      } = formik;

      const {financeConfig,payoutAccounts} = values
      const {isReadOnly} = permissions
    
     const currency = useMemo(()=>!is.empty(paymentDetails) && paymentDetails.currency?paymentDetails.currency:"",[paymentDetails]);
      const handlePaymentTypeChange = (e) =>{
            const value=e.target.value;
            if(value==="monthly")setFieldValue("financeConfig.charger_payment_details.monthly_payment",true)
            else setFieldValue("financeConfig.charger_payment_details.monthly_payment",false);
      }

      const handleCommissionTypeChange = (e) =>{
        const value=e.target.value;
        const payoutCalcValue = financeConfig.payout_details.payout_rule

        if((payoutCalcValue===1 || payoutCalcValue===3||payoutCalcValue===5) &&  value==="fixed cost"){
          dispatch(
            setAlert({
              open: true,
              message: "Please first select a payout calculation rule that takes OAZA commission(in percentage) into consideration",
              severity: enums.SEVERITY.ERROR,
            })
          );
          return
        }else if((payoutCalcValue===2 || payoutCalcValue===4||payoutCalcValue===6) &&  value==="percentage"){
          dispatch(
            setAlert({
              open: true,
              message: "Please first select a payout calculation rule that takes OAZA commission(in fixed amount) into consideration",
              severity: enums.SEVERITY.ERROR,
            })
          );
          return
        }
        if(value==="percentage")setFieldValue("financeConfig.payout_details.chargemiles_commission_in_percent",true)
        else setFieldValue("financeConfig.payout_details.chargemiles_commission_in_percent",false);
  }

  const handlePGPaymentMonthlyBearerChange = (e) =>{
    const value=e.target.value;
    setFieldValue("financeConfig.transaction_payment_details.pg_charges_bearer",value)
}

const payoutCalcRuleChange = (e) =>{
  const value=e.target.value;
  if(value===1 || value===3||value===5){
    setFieldValue("financeConfig.payout_details.chargemiles_commission_in_percent",true)
  }else if(value===2 || value===4||value===6){
    setFieldValue("financeConfig.payout_details.chargemiles_commission_in_percent",false)
  }

  setFieldValue("financeConfig.payout_details.payout_rule",value)
}


  return (
    <>       
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} marginBottom={2}>
            <Typography sx={{ ml: 1 }} variant="subtitle1">
               CHARGER PAYMENT SETTINGS
            </Typography>
            <Divider className="text-muted" variant="fullWidth" />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
          <FormControl>
          <RadioGroup
            row
            aria-labelledby="chargemiles-charger-settings-type-button-group"
            name="chargemiles-charger-settings-type-row-radio-buttons-group"
            value={financeConfig.charger_payment_details.monthly_payment ? "monthly" : "annual"}
            onChange={handlePaymentTypeChange}
          >
            <FormControlLabel
              value="monthly"
              control={<Radio />}
              label="Monthly Cost"
            />
            <FormControlLabel
              value="annual"
              control={<Radio />}
              label="Annual Cost"
            />
          </RadioGroup>
        </FormControl>
          </Grid>
         { financeConfig.charger_payment_details.monthly_payment ?
         <>
         <Grid item xs={12} lg={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Monthly-Charger-Payment-DC-Charger`}
                label="Monthly Payment Amount (DC Charger)"
                size="small"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                  }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
                {...getFieldProps(
                  `financeConfig.charger_payment_details.dc_charges_monthly`
                )}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.charger_payment_details &&
                    errors.financeConfig.charger_payment_details &&
                    touched.financeConfig.charger_payment_details
                      .dc_charges_monthly &&
                    errors.financeConfig.charger_payment_details
                      .dc_charges_monthly
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.charger_payment_details &&
                  errors.financeConfig.charger_payment_details &&
                  touched.financeConfig.charger_payment_details
                    .dc_charges_monthly &&
                  errors.financeConfig.charger_payment_details
                    .dc_charges_monthly
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Monthly-Charger-Payment-AC-Charger`}
                label="Monthly Payment Amount (AC Charger)"
                size="small"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                  }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
                {...getFieldProps(
                  `financeConfig.charger_payment_details.ac_charges_monthly`
                )}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.charger_payment_details &&
                    errors.financeConfig.charger_payment_details &&
                    touched.financeConfig.charger_payment_details
                      .ac_charges_monthly &&
                    errors.financeConfig.charger_payment_details
                      .ac_charges_monthly
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.charger_payment_details &&
                  errors.financeConfig.charger_payment_details &&
                  touched.financeConfig.charger_payment_details
                    .ac_charges_monthly &&
                  errors.financeConfig.charger_payment_details
                    .ac_charges_monthly
                }
              />
            </FormControl>
          </Grid>
          </>
          :
          <>
          <Grid item xs={12} lg={4} md={4}>
          <FormControl fullWidth size="small">
            <TextField
              id={`One-Time-Annual-Payment-DC-Charger`}
              label="One Time Annual Payment Amount (DC Charger)"
              size="small"
              variant="outlined"
              InputLabelProps={{
                  shrink: true,
                }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
              }}
              {...getFieldProps(
                `financeConfig.charger_payment_details.dc_charges_annual`
              )}
              error={Boolean(
                touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.charger_payment_details &&
                  errors.financeConfig.charger_payment_details &&
                  touched.financeConfig.charger_payment_details
                    .dc_charges_annual &&
                  errors.financeConfig.charger_payment_details
                    .dc_charges_annual
              )}
              helperText={
                touched &&
                touched.financeConfig &&
                errors &&
                errors.financeConfig &&
                touched.financeConfig.charger_payment_details &&
                errors.financeConfig.charger_payment_details &&
                touched.financeConfig.charger_payment_details
                  .dc_charges_annual &&
                errors.financeConfig.charger_payment_details
                  .dc_charges_annual
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <FormControl fullWidth size="small">
            <TextField
              id={`One-Time-Annual-Payment-AC-Charger`}
              label="One Time Annual Payment Amount (AC Charger)"
              size="small"
              variant="outlined"
              InputLabelProps={{
                  shrink: true,
                }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
              }}
              {...getFieldProps(
                `financeConfig.charger_payment_details.ac_charges_annual`
              )}
              error={Boolean(
                touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.charger_payment_details &&
                  errors.financeConfig.charger_payment_details &&
                  touched.financeConfig.charger_payment_details
                    .ac_charges_annual &&
                  errors.financeConfig.charger_payment_details
                    .ac_charges_annual
              )}
              helperText={
                touched &&
                touched.financeConfig &&
                errors &&
                errors.financeConfig &&
                touched.financeConfig.charger_payment_details &&
                errors.financeConfig.charger_payment_details &&
                touched.financeConfig.charger_payment_details
                  .ac_charges_annual &&
                errors.financeConfig.charger_payment_details
                  .ac_charges_annual
              }
            />
          </FormControl>
        </Grid>
        </>
        }
          <Grid item xs={12} lg={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Charger-Payment-Tax`}
                label="Plus Taxes"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `financeConfig.charger_payment_details.tax`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.charger_payment_details &&
                    errors.financeConfig.charger_payment_details &&
                    touched.financeConfig.charger_payment_details.tax &&
                    errors.financeConfig.charger_payment_details.tax
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.charger_payment_details &&
                  errors.financeConfig.charger_payment_details &&
                  touched.financeConfig.charger_payment_details.tax &&
                  errors.financeConfig.charger_payment_details.tax
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Charger-Payment-Other-Fixed-Cost`}
                label="Any Other Fixed Cost"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `financeConfig.charger_payment_details.other_fixed_cost`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{currency}</InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.charger_payment_details &&
                    errors.financeConfig.charger_payment_details &&
                    touched.financeConfig.charger_payment_details
                      .other_fixed_cost &&
                    errors.financeConfig.charger_payment_details
                      .other_fixed_cost
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.charger_payment_details &&
                  errors.financeConfig.charger_payment_details &&
                  touched.financeConfig.charger_payment_details
                    .other_fixed_cost &&
                  errors.financeConfig.charger_payment_details.other_fixed_cost
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12} marginBottom={2} marginTop={4}>
            <Typography sx={{ ml: 1 }} variant="subtitle1">
              TRANSACTION PAYMENT SETTINGS
            </Typography>
            <Divider className="text-muted" variant="fullWidth" />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <FormControl
              fullWidth
              size="small"
              error={Boolean(
                touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.transaction_payment_details &&
                  errors.financeConfig.transaction_payment_details &&
                  touched.financeConfig.transaction_payment_details
                    .pg_charges_bearer &&
                  errors.financeConfig.transaction_payment_details
                    .pg_charges_bearer
              )}
            >
              <InputLabel id={`Transaction-Payment-PG-Charges-Bearer`}>
                Payment Gateway Charges Bearer
              </InputLabel>
              <Select
                labelId={`Transaction-Payment-PG-Charges-Bearer`}
                id={`Transaction-Payment-PG-Charges-Bearer-Id`}
                label="Payment Gateway Charges Bearer"
                {...getFieldProps(
                  `financeConfig.transaction_payment_details.pg_charges_bearer`
                )}
                value={
                  financeConfig.transaction_payment_details.pg_charges_bearer
                }
                onChange={handlePGPaymentMonthlyBearerChange}
              >
                <MenuItem value="EV" key="payment-gateway-bearer-ev-driver">
                  <em>EV Driver</em>
                </MenuItem>
                <MenuItem value="CPO" key="payment-gateway-bearer-cpo">
                  <em>CPO</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Transaction-Payment-Payment-Gateway-Charges`}
                label="Payment Gateway Charges"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `financeConfig.transaction_payment_details.payment_gateway_percent`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">% of the payment captured</InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.transaction_payment_details &&
                    errors.financeConfig.transaction_payment_details &&
                    touched.financeConfig.transaction_payment_details
                      .payment_gateway_percent &&
                    errors.financeConfig.transaction_payment_details.payment_gateway_percent
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.transaction_payment_details &&
                  errors.financeConfig.transaction_payment_details &&
                  touched.financeConfig.transaction_payment_details
                    .payment_gateway_percent &&
                  errors.financeConfig.transaction_payment_details.payment_gateway_percent
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Transaction-Payment-Tax`}
                label="Plus Taxes"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `financeConfig.transaction_payment_details.tax`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">% </InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.transaction_payment_details &&
                    errors.financeConfig.transaction_payment_details &&
                    touched.financeConfig.transaction_payment_details
                      .tax &&
                    errors.financeConfig.transaction_payment_details.tax
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.transaction_payment_details &&
                  errors.financeConfig.transaction_payment_details &&
                  touched.financeConfig.transaction_payment_details
                    .tax &&
                  errors.financeConfig.transaction_payment_details.tax
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12} marginBottom={2} marginTop={4}>
            <Typography sx={{ ml: 1 }} variant="subtitle1">
              PAYOUT SETTINGS
            </Typography>
            <Divider className="text-muted" variant="fullWidth" />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
                        <FormControl
                          size="small"
                          fullWidth 
                          error={Boolean(
                            touched &&
                              touched.financeConfig &&
                              errors &&
                              errors.financeConfig &&
                              touched.financeConfig.payout_details &&
                              errors.financeConfig.payout_details &&
                              touched.financeConfig.payout_details.payout_rule &&
                              errors.financeConfig.payout_details.payout_rule
                          )}>
                          <InputLabel id={`Payout-Rule-Type-Input-Label`} >
                          Payout Calculations Rule
                          </InputLabel>
                          <Select
                            labelId={`Payout-Rule-Type-Input-Label`}
                            id={`Payout-Rule-Type-Input-Label-Id`}
                            value={financeConfig.payout_details.payout_rule}
                            label="Payout Calculations Rule"
                            {...getFieldProps(`financeConfig.payout_details.payout_rule`)}
                            onChange={payoutCalcRuleChange}
                          >
                            {possiblePayoutRules.map(
                              ({ label, value, disabled }, index) => {
                                return (
                                  <MenuItem
                                    key={`Payout-Calculations-Rule-${index}-${label}`}
                                    value={value}
                                    disabled={disabled}
                                  >
                                    {label}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <FormControl
              fullWidth
              size="small"
              error={Boolean(
                touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.payout_details &&
                  errors.financeConfig.payout_details &&
                  touched.financeConfig.payout_details
                    .tax_bearer &&
                  errors.financeConfig.payout_details
                    .tax_bearer
              )}
            >
              <InputLabel id={`Transaction-Payment-Tax-Bearer`}>
              Tax Bearer
              </InputLabel>
              <Select
                labelId={`Transaction-Payment-Tax-Payment-Bearer`}
                id={`Transaction-Payment-Tax-Payment-Bearer-Id`}
                label="Tax Bearer"
                {...getFieldProps(
                  `financeConfig.payout_details.tax_bearer`
                )}
                value={
                  financeConfig.payout_details.tax_bearer}
              >
                <MenuItem value="CPO" key="tax-payment-bearer-cpo">
                  <em>CPO</em>
                </MenuItem>
                <MenuItem value="OAZA" key="tax-payment-bearer-not-cpo">
                  <em>Oaza</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Payout-Settings-Tax`}
                label="Plus Taxes"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `financeConfig.payout_details.tax`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.payout_details &&
                    errors.financeConfig.payout_details &&
                    touched.financeConfig.payout_details.tax &&
                    errors.financeConfig.payout_details.tax
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.payout_details &&
                  errors.financeConfig.payout_details &&
                  touched.financeConfig.payout_details.tax &&
                  errors.financeConfig.payout_details.tax
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12} marginTop={2}>
            <Typography sx={{ ml: 1 ,fontWeight:600}} variant="body1" >
              Oaza Commission 
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
          <FormControl>
          <RadioGroup
            row
            aria-labelledby="chargemiles-commission-type-button-group"
            name="chargemiles-commission-type-row-radio-buttons-group"
            value={financeConfig.payout_details.chargemiles_commission_in_percent ? "percentage" : "fixed cost"}
            onChange={handleCommissionTypeChange}
          >
            <FormControlLabel
              value="percentage"
              control={<Radio />}
              label="In Percentage"
            />
            <FormControlLabel
              value="fixed cost"
              control={<Radio />}
              label="Fixed Cost"
            />
          </RadioGroup>
        </FormControl>
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <FormControl fullWidth size="small">
              {financeConfig.payout_details.chargemiles_commission_in_percent ?
              <TextField
                id={`Payout-Chargemiles-Commission-Percent`}
                label="Oaza commission percent"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `financeConfig.payout_details.chargemiles_commission_percent`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">% per unit</InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.payout_details &&
                    errors.financeConfig.payout_details &&
                    touched.financeConfig.payout_details
                      .chargemiles_commission_percent &&
                    errors.financeConfig.payout_details.chargemiles_commission_percent
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.payout_details &&
                  errors.financeConfig.payout_details &&
                  touched.financeConfig.payout_details
                    .chargemiles_commission_percent &&
                  errors.financeConfig.payout_details.chargemiles_commission_percent
                }
              /> :
              <TextField
                id={`Payout-Chargemiles-Commission-Cost`}
                label="Chargemiles commission cost"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `financeConfig.payout_details.chargemiles_commission_cost`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">per unit</InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">{currency}</InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched &&
                    touched.financeConfig &&
                    errors &&
                    errors.financeConfig &&
                    touched.financeConfig.payout_details &&
                    errors.financeConfig.payout_details &&
                    touched.financeConfig.payout_details
                      .chargemiles_commission_cost &&
                    errors.financeConfig.payout_details.chargemiles_commission_cost
                )}
                helperText={
                  touched &&
                  touched.financeConfig &&
                  errors &&
                  errors.financeConfig &&
                  touched.financeConfig.payout_details &&
                  errors.financeConfig.payout_details &&
                  touched.financeConfig.payout_details
                    .chargemiles_commission_cost &&
                  errors.financeConfig.payout_details.chargemiles_commission_cost
                }
              />
              }
            </FormControl>
          </Grid>
          {payoutAccounts.every(({business_name})=>!is.empty(business_name)) && <Grid item xs={12} lg={12} marginBottom={1} marginTop={2}>
            <Typography sx={{ ml: 1 ,fontWeight:600}} variant="body1" >
              Third-Party Transaction Payout 
            </Typography>
          </Grid>}
          {
               payoutAccounts.map(({business_name},index)=>{

                if(business_name){
                  return ( 
                    <>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl fullWidth size="small">
                      <TextField
                        id={`Monthly-Transaction-payment-payout-accounts-share`}
                        label={`Account of business named - ${business_name} `}
                        size="small"
                        variant="outlined"
                        {...getFieldProps(
                          `payoutAccounts[${index}].payout_percentage`
                        )}
                        InputLabelProps={{
                            shrink: true,
                          }}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
      
                        error={Boolean(
                          touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                                      touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                                      touched.payoutAccounts[index].payout_percentage &&
                                        errors.payoutAccounts[index].payout_percentage                )}
                        helperText={
                          touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                          touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                          touched.payoutAccounts[index].payout_percentage &&
                            errors.payoutAccounts[index].payout_percentage                }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
            <FormControl fullWidth size="small">
              <TextField
                id={`Monthly-Transaction-payment-payout-accounts-taxes`}
                label="Plus Taxes"
                size="small"
                variant="outlined"
                {...getFieldProps(
                  `payoutAccounts[${index}].tax_percentage`
                )}
                InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                error={Boolean(
                  touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                              touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                              touched.payoutAccounts[index].tax_percentage &&
                                errors.payoutAccounts[index].tax_percentage                )}
                helperText={
                  touched && touched.payoutAccounts && errors && errors.payoutAccounts &&
                  touched.payoutAccounts[index] && errors.payoutAccounts[index] &&
                  touched.payoutAccounts[index].tax_percentage &&
                    errors.payoutAccounts[index].tax_percentage
                  }
              />
            </FormControl>
          </Grid>
                  </>
                  )
                }
                return <></>
               })
          }
          
        </Grid>
    
    </>
  );
}

export default TeamFinanceForm
import { LoadingButton } from "@mui/lab";
import PlusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { BaseTable } from "../BaseTable/BaseTable";
import { useNavigate, useParams } from "react-router-dom";
import { customerTagFormColumns } from "./customer.constant";
import { useDispatch } from "react-redux";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";

const CustomerTagsForms = ({
  availableTagsList,
  customerTags,
  isMutatingCustomerTags,
  refetchAllTheLists,
  isFetchingCustomerTags,
  handleTagSubmit,
  removeFunction,
  isNewCustomer,
  saveFormData
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const hasCreateAccess = useAccess(FEATURE.TAG, "C");
  const onClose = useCallback(() =>{
    dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
      detailsId:"",
      tabOpenedFor:"",
      tabOpened:""
    }));
    navigate("/customer")
   },[dispatch,navigate]);
  //  console.log(`HasCreateAccess : ${hasCreateAccess}`)
  const clearSelected = () => setSelectedTags([]);
  const handleAddNewTag = useCallback(() => {

    if(hasCreateAccess){
      dispatch(tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
        detailsId:isNewCustomer?"":id,
        tabOpenedFor:"customer",
        tabOpened:"tags"
      }));
      saveFormData();
      navigate(`/tag/new`);
    }
  }, [saveFormData,navigate,dispatch,hasCreateAccess,id,isNewCustomer]);

  return (
    <Box>
      <Grid container mb={4} direction="row">
        <Grid item md={6} lg={6}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={availableTagsList ?? []}
              value={selectedTags}
              onChange={(event, selectedTagsArr) => {
                // console.log(selectedTagsArr);
                // attachRef.current.tagsList = newValue;
                setSelectedTags(selectedTagsArr);
              }}
              filterOptions={(options) =>
                options?.filter(
                  (availableTag) =>
                    !customerTags?.some(
                      (customerTag) => customerTag?.tag === availableTag?.tag
                    )
                )
              }
              filterSelectedOptions={true}
              getOptionLabel={(option) => option.tag}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Avaliable Tags"
                  placeholder="Select Tag"
                />
              )}
              sx={{ width:"87%",minWidth:"250px" }}
            />
          </FormControl>
        </Grid>
        <Grid item md={hasCreateAccess?4:6} lg={hasCreateAccess?4:6} textAlign="start" pt={1.5} pl={1} >
            <LoadingButton
              variant="contained"
              onClick={() => handleTagSubmit(selectedTags, clearSelected)}
              size="medium"
             sx={{marginRight:"6px"}}
              loading={isMutatingCustomerTags}
            >
              Attach Tags
            </LoadingButton>
            {!isNewCustomer && (
              <Button
                variant="outlined"
                onClick={onClose}
                size="medium"
              >
                Cancel
              </Button>
            )}
        </Grid>
        {
        hasCreateAccess && 
        <Grid item md={2} lg={2} textAlign="start" pt={1.6} pl={1}>
          <Tooltip title="Create New RFID Tag" placement="right-end">
            <LoadingButton
              size="medium"
              type="button"
              variant="contained"
              onClick={() => handleAddNewTag()}
            >
              <Icon icon={PlusFill} fontSize="1.3em" />
            </LoadingButton>
          </Tooltip>
        </Grid>}
      </Grid>
      <BaseTable
        rows={customerTags ?? []}
        columns={isNewCustomer?customerTagFormColumns(refetchAllTheLists,removeFunction):customerTagFormColumns(refetchAllTheLists)}
        getRowId={(row) => row.id}
        loading={isFetchingCustomerTags}
      />
    </Box>
  );
};

export default CustomerTagsForms;

import { sentenceCase } from 'change-case';
// material
import {
  Stack,
  Avatar,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
//
import DataTable from 'src/components/DataTable';
import enums from 'src/util/enums';
import userCellHeaders from 'src/components/_dashboard/user/cellHeaders';
import USERLIST from '../_mocks_/user';

const schema = ['name', 'company', 'role', 'isVerified', 'status'];

export default function User() {

  const renderCell = (value, prop, cellValue) => {
    if (prop === 'name') {
      return (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={value} src={cellValue.avatarUrl} />
          <Typography variant="subtitle2" noWrap>{value}</Typography>
        </Stack>
      )
    }
    if (prop === 'isVerified') {
      return value ? 'Yes' : 'No'
    }
    if (prop === 'status') {
      return (
        <Label variant="ghost" color={(cellValue.status === 'banned' && 'error') || 'success'}>
          {sentenceCase(cellValue.status)}
        </Label>
      )
    }
    return <span>{value}</span>
  }

  return (
    <Page title="User | Smart-CMS" subtitle="User" actionButton={true} actionButtonText="New User">
      <DataTable 
        toolbar={enums.toolbars.USER}
        cellHeaders={userCellHeaders}
        data={USERLIST}
        orderByColumn='name'
        selectedColumnName='name'
        dataRenderSchema={schema}
        renderCell={renderCell}
        actions={true}
      />
    </Page>
  );
}

import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import logo from "../image/logo/smartCMSlogo.svg";
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box component="img" src={logo} />
  );
}

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Stack,
} from "@mui/material";
import Page from "src/components/Page";
import { BackButton } from "src/common/components/BackButton";
import { useAuth } from "src/hooks/useAuth.hook";
import {
    cmCustomerTabList,
    cmWalletHistoryColumns,
    cmTransactionsListColumns
} from "./user.constant";
import { useList } from "src/hooks/useFetch.hook";
import { FEATURE } from "src/util/enums";
import { useSelector } from "react-redux";
import { useAccess } from "src/hooks/useAccess.hook";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { RootTabItemStyles } from "src/theme/overrides/Tabs";
import { styled } from "@mui/material/styles";
import { BaseTable } from "../BaseTable/BaseTable";

const RootTabStyle = styled(TabList)(({ theme, length }) => ({
  width: "98%",
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  "& .MuiTabs-flexContainer": {
    justifyContent: "left",
  },
  "& .MuiTab-root": {
    width: `${100 / length}%`,
  },
}));

const CMCustomerWalletTransactionHistory = () => {
  const { id } = useParams();
  const { teamId } = useSelector((state) => state.teams);
  const { data: customers, isFetching } = useList("cmWalletHistory", true, id);
  const { data: transactions, isFetching: isTransactionFetching } = useList("cmCustomerTransactionHistory", true, teamId, id);
  const isReadOnly = useAccess(FEATURE.CUSTOMERS, "R");
  const [selectedTab, setSelectedTab] = useState(cmCustomerTabList[0].value);
  const user = useAuth();

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  };
  return (
    <Page title="Customer | Oaza - CMS">
      <BackButton path="/cm-customer" text="Customer Wallet & Transaction History" />
      <TabContext value={selectedTab}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItem="center"
          sx={{ width: "100%" }}

        >
          <RootTabStyle
            indicatorColor="transparent"
            centered
            length={cmCustomerTabList.length}
            onChange={handleTabChange}
          >
            {cmCustomerTabList.map((tab) => (
              <RootTabItemStyles value={tab.value} label={tab.label} />
            ))}
          </RootTabStyle>
        </Stack>
        <Card sx={{ p: 4 }}>
          <TabPanel value="wallet_history">
              <BaseTable
                rows={customers ?? []}                
                columns={cmWalletHistoryColumns(isReadOnly)}
                loading={isFetching}
                getRowId={(row) => row.id}
                pageSize={10}
              />
          </TabPanel>
          <TabPanel value="transaction_history">
            <BaseTable
                rows={transactions ?? []}                
                columns={cmTransactionsListColumns(isReadOnly, id)}
                loading={isTransactionFetching}
                getRowId={(row) => row.transactionId}
                pageSize={10}
              />
          </TabPanel>
        </Card>
      </TabContext>
    </Page>
  );
};

export default CMCustomerWalletTransactionHistory;

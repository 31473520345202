import axios from "../util/axios";

export const fetchApi = async ({ url, method = "GET", body, headers = null }) => {
  try {
    const { data } = await axios({
      url,
      method,
      ...((method === "GET" || method === "DELETE") && { params: body }),
      ...((method === "POST" || method === "PUT" || method === "PATCH") && { data: body }),
      ...(headers && headers),
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchWithError = ({ queryKey }) => {
  const [, queryParams] = queryKey ?? {};
  const { url, method = "GET", body } = queryParams || {};
  return fetchApi({ url, method, body });
};

export const fetchInfinite = async ({ queryKey, pageParam = 1 }) => {
  const [, queryParams] = queryKey ?? {};
  const { url, method = "GET", body } = queryParams || {};
  const { data } = await fetchApi({ url: queryKey[0] === "viewReport" ? `${url}${pageParam}`:`${url}/${pageParam}`, method, body });
  return data;
};

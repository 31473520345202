import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Typography,Grid} from '@mui/material';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import RegisterForm from 'src/components/authentication/register/RegisterForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Register | Smart-CMS">

      <Grid container spacing={2}> 
        
        <Grid item md={4}> 
            <MHidden width="mdDown">
            <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
             Manage the job more effectively with Minimal
            </Typography>
            <img alt="register" src="/static/illustrations/illustration_register.png" />
            </SectionStyle>
            </MHidden>
        </Grid>
        
        <Grid item xs={12} md={8}> 
            <ContentStyle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h4" gutterBottom>
                Requied Details
            </Typography>
          </Box>


          <RegisterForm />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            By registering, I agree to Minimal&nbsp;
            <Box component="a" href="https://oazaenergy.com/terms-of-services" target="_blank" rel="noopener noreferrer" sx={{ color: 'text.primary', textDecoration: 'underline' }}>
              Terms of Service
            </Box>
            &nbsp;and&nbsp;
            <Box component="a" href="https://oazaenergy.com/privacy-policy" target="_blank" rel="noopener noreferrer" sx={{ color: 'text.primary', textDecoration: 'underline' }}>
              Privacy Policy
            </Box>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
        </Grid>

      </Grid>


      {/* <MHidden width="mdDown">
        <SectionStyle>
                 </SectionStyle>
      </MHidden>
    */}
    {/* <Container>
        <ContentStyle></ContentStyle>
      </Container> */}
    </RootStyle>
  );
}

import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { fDateTime, yearFirstDateTimeFormat } from "src/utils/formatTime";
import { CustomerActionMenu } from "./CustomerActionMenu";
import CustomerTagActionMenu from "./CustomerTagActionMenu";

const dateFormat = "yyyy-MM-dd hh:mm a";

export const userListColumns = (isReadOnly)=>([
  {
    field: "fullName",
    headerName: "Name",
    flex: 0.9,
    minWidth:150,
    valueGetter: (params) => `${params?.row?.firstName ? params?.row?.firstName : ""} ${params?.row?.lastName? params?.row?.lastName : ""}`
  },
  {
    field: "email",
    headerName: "Email ID",
    flex: 1.2,
    minWidth:250,
    renderCell: ({ value, id }) => (
      <>
     {isReadOnly?value: <Link underline="none" component={RouterLink} to={`${id}`}>
        {value}
      </Link>}
      </>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",
    flex: 1,
    minWidth:180,
  },
  {
    field: "balanceAmount",
    headerName: "Wallet Balance",
    flex: 1,
    minWidth: 120
  },
  {
    field: "active",
    headerName: "Status",
    flex: 1,
    renderCell: ({ value }) => {
      let statusColor = value === "N" ? "red" : "#00AB55";
      let status = value === "N" ? "Disabled" : "Enabled";
      return (
        <span style={{ marginLeft: "10px", color: statusColor }}>{status}</span>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created on",
    type: "date",
    flex: 1,
    minWidth: 200,
    valueFormatter: ({ value }) => {
      return fDateTime(value, dateFormat);
    },
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: CustomerActionMenu,
  },
]);

export const cmWalletHistoryColumns = (isReadOnly)=>([
  {
    headerName: "Sr. No.",
    field: "id",
    flex: 0.9,
    minWidth:100,
  },
  {
    field: "payment_type",
    headerName: "Payment Type",
    flex: 1.2,
    minWidth:200,
  },
  {
    field: "payment_method",
    headerName: "Payment Method",
    flex: 1.2,
    minWidth:200,
  },
  {
    field: "payment_on",
    headerName: "Payment On",
    type: "date",
    flex: 1,
    minWidth: 200,
    valueFormatter: ({ value }) => {
      return fDateTime(value, dateFormat);
    },
  },
  {
    field: "debit",
    headerName: "Debit",
    flex: 1.2,
    minWidth:100,
    renderCell: ({ value }) => {
      return (
        <span style={{ marginLeft: "10px", color: "red" }}>{value === 0 ? '' : value}</span>
      );
    },
  },
  {
    field: "credit",
    headerName: "Credit",
    flex: 1.2,
    minWidth:100,
    renderCell: ({ value }) => {
      return (
        <span style={{ marginLeft: "10px", color: "#00AB55" }}>{value === 0 ? '' : value}</span>
      );
    },
  },
]);

export const cmTransactionsListColumns = (isReadOnly, id)=>([
  {
    field: "transactionId",
    headerName: "Transaction ID",
    flex: 0.7,
    minWidth: 150,
    renderCell: ({ value, id: tId }) => (
      <>
      {isReadOnly?value:<Link underline="none" component={RouterLink} to={`/transactions/${tId}?source=cm-customer/${id}`}>
        {value}
      </Link>}
      </>
    ),
  },
  {
    field: "chargepoint_id",
    headerName: "ChargeBox ID",
    flex: 0.7,
    minWidth: 120,
  },
  {
   field:"payment_type",
   headerName:"Payment Type",
   flex:0.5,
   minWidth: 130,
  },
  {
    field: "totalAmountPaid",
    headerName: "Amount Paid",
    flex: 0.6,
    minWidth: 130,
  },
  {
    field: "totalAmountCharged",
    headerName: "Charging Cost",
    flex: 0.6,
    minWidth: 140,
  },
]);

export const getCustomerDefaultValues = (teamId) => ({
  companyId: teamId,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  locationLatitude: "",
  locationLongitude: "",
  street: "",
  house_number: "",
  zipCode: "",
  state: "",
  country: "",
  // ocppTagId: ""
});

export const customerFormTabs = [
  {value:"general",label:"GENERAL"},
  {value:"address",label:"ADDRESS"},
  {value:"tags",label:"RFID TAGS"},
];

export const cmCustomerTabList = [
  {
    value: "wallet_history",
    label: "Wallet History",
  },
  {
    value: "transaction_history",
    label: "Transaction History",
  },
];

export const hideForTabs = ["tags"];


export const customerTagFormColumns = (refetch,removeTagFn)=>[
  {
    field: "tag",
    headerName: "RFID Tag",
    flex: 1,
    renderCell: ({value,row:{ocpp_tag_pk}}) => {
      return(
      <Link underline="none" component={RouterLink} to={`/tag/${ocpp_tag_pk}`}>
        {value}
      </Link>
    )},
  },
  {
    field: "parenttag",
    headerName: "Parent Group",
    flex: 1,
  },
  {
    field: "expirydate",
    headerName: "Expiry Date/Time",
    flex: 1,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    )
  },
  {
    field: "in_transaction",
    headerName: "In Transactions",
    type: "boolean",
    flex: 1,
  },
  {
    field: "blocked",
    headerName: "Blocked",
    type: "boolean",
    flex: 1,
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell:(props)=> <CustomerTagActionMenu {...props} refetch={refetch} removeTagFn={removeTagFn}/>,
  },
];
import { LoadingButton } from "@mui/lab";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { Form, FormikProvider, useFormik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutate } from "src/hooks/useFetch.hook";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import {
  remoteOperationTaskUrl,
  remoteOperationUrl,
} from "src/react-query/endPoints";
import { fetchWithError } from "src/react-query/fetchWithError";
import { queryKeys } from "src/react-query/queryKeys";
import { remoteOperationsDateFormat } from "src/utils/formatTime";
import { remoteOperationConfig } from "./remoteOperationConfig";
import { remoteOpsStatusSlice } from "src/store/remoteOpsStatus/remoteOpsStatus.slice";
import { useAuth } from "src/hooks/useAuth.hook";

export const RemoteOperationForm = ({
  operation,
  initialValues,
  validationSchema,
  onClose,
  chargerId,
  chargerPk,
  queryKeysDropdown,
}) => {
  const queryClient = useQueryClient();  
  const dispatch = useDispatch();
  const [dateTimeErr, setDateTimeErr] = useState({
    fieldName: "",
    message: "",
  });
  const { teamId } = useSelectedTeam();
  const {user:{email}} = useAuth();
  const results = useQueries({
    queries:
      queryKeysDropdown?.map(({ queryKey: key, usePk,useEmail }) => ({
        queryKey: !useEmail?queryKeys[key](teamId, usePk ? chargerPk : chargerId):queryKeys[key](teamId,usePk ? chargerPk : chargerId,email ),
        queryFn: fetchWithError,
        staleTime: 0,
        select: (data) => ({
          data: data?.data,
          key,
        }),
      })) ?? [],
  });

  const { allData } = useSelector((state) => state.chargingProfile)

  const chargingProfileOptions = useMemo(() => {
    const data = [];
    allData.forEach((item) => {
        data.push({
            charging_profile_pk: item.charging_profile_pk,
            description: item.description
        });
    });
    return data;
  }, [allData]);

  const dropdownOptions = useMemo(
    () =>
      results?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.data?.key]: curr?.data?.data,
        }),
        {}
      ) ?? {},
    [results]
  );

  const onRemoteOperationStatus=(data)=>{
   if (onClose) onClose()
  dispatch(remoteOpsStatusSlice.actions.setRemoteOps({open:true,operation,data:{chargerId,response:data.data.status}}));
  resetForm()
  queryKeysDropdown.forEach(({queryKey:key,usePk,useUserPk})=>queryClient.invalidateQueries(queryKeys[key](teamId, usePk ? chargerPk : chargerId)))  

  }

  const { mutate, reset,isLoading } = useMutate("chargers", {}, false);

  const onOperationSent = (data) => {
    reset();
    mutate(
      {
        url: remoteOperationTaskUrl(chargerId, data?.data?.taskId),
        method: "GET",
      },
      {
        onSuccess:onRemoteOperationStatus,
      }
    );
  };

  const onSubmit = (data, { setSubmitting, setErrors }) => {
    let payload = { ...data };
    switch (operation) {
      case "GetDiagnostics":
        payload.start = remoteOperationsDateFormat(payload.start);
        payload.stop = remoteOperationsDateFormat(payload.stop);
        break;
      case "UpdateFirmware":
        payload.retrieveDate = remoteOperationsDateFormat(payload.retrieveDate);
        payload.stop = remoteOperationsDateFormat(payload.stop);
        break;
      case "ChangeConfiguration":
        let errors = { customConfKey: "", confKey: "" };
        if (payload.keyType === "Custom") {
          errors = {
            ...errors,
            customConfKey: !payload.customConfKey
              ? "This field is required"
              : "",
          };
        } else {
          errors = {
            ...errors,
            confKey: !payload.confKey ? "This field is required" : "",
          };
        }
        if (Object.values(errors).includes("This field is required")) {
          setErrors(errors);
          setSubmitting(false);
          return;
        }
        break;
      default:
        console.log("submitting...");
        break;
    }
    mutate(
      {
        url: remoteOperationUrl,
        method: "POST",
        body: {
          parameters: payload,
          chargerId,
          remoteOperationType: operation,
        },
      },
      {
        onSuccess: onOperationSent,
      }
    );
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
    setErrors,
  } = formik;

  const allowedDateTime = (currentFieldName, allFieldsArr) => {
    const correspondingFieldName = allFieldsArr.find(
      ({ fieldName, type }) =>
        fieldName !== currentFieldName && type === "DATE/TIME"
    ).fieldName;
    let allowed;
    switch (currentFieldName) {
      case "start":
      case "retrieveDate":
        allowed = new Date();
        allowed.setMinutes(allowed.getMinutes() - 5);
        break;
      case "stop":
        if (values[correspondingFieldName]) {
          allowed = new Date(values[correspondingFieldName]);
          allowed.setHours(allowed.getHours() + 1);
        } else allowed = new Date();
        break;
      default:
        break;
    }
    return allowed;
  };

  const FormFieldStatus = (componentDependentOn, referenceValue) => {
    return values[componentDependentOn] === referenceValue;
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        id="remoteOperations"
        noValidate
        onSubmit={handleSubmit}
      >
        <Card sx={{ p: 2, minHeight: 200 }}>
          <Grid container spacing={2}>
            {remoteOperationConfig[operation]?.map(
              (
                {
                  fieldName,
                  required,
                  type,
                  label,
                  options,
                  queryKey,
                  formatOptions,
                  componentDependentOn,
                },
                index,
                allFieldsArr
              ) => {
                switch (type) {
                  case "TEXT":
                    return (
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth size="small">
                          <TextField
                            name={fieldName}
                            required={
                              componentDependentOn
                                ? FormFieldStatus(
                                    componentDependentOn["field"],
                                    componentDependentOn[
                                      "requiredCriteriaValue"
                                    ],
                                    required
                                  )
                                : required
                            }
                            disabled={
                              componentDependentOn
                                ? FormFieldStatus(
                                    componentDependentOn["field"],
                                    componentDependentOn[
                                      "disabledCriteriaValue"
                                    ],
                                    required
                                  )
                                : false
                            }
                            label={label}
                            size="small"
                            {...getFieldProps(fieldName)}
                            error={Boolean(
                              touched[fieldName] && errors[fieldName]
                            )}
                            helperText={touched[fieldName] && errors[fieldName]}
                          />
                        </FormControl>
                      </Grid>
                    );
                  case "DROPDOWN":
                    return (
                      <Grid item xs={12} md={6}>
                        <FormControl
                          fullWidth
                          size="small"
                          error={Boolean(
                            touched[fieldName] && errors[fieldName]
                          )}
                        >
                          <InputLabel id={fieldName}>{label}</InputLabel>
                          <Select
                            labelId={fieldName}
                            label={label}
                            {...getFieldProps(fieldName)}
                            required={
                              componentDependentOn
                                ? FormFieldStatus(
                                    componentDependentOn["field"],
                                    componentDependentOn[
                                      "requiredCriteriaValue"
                                    ],
                                    required
                                  )
                                : required
                            }
                            disabled={
                              componentDependentOn
                                ? FormFieldStatus(
                                    componentDependentOn["field"],
                                    componentDependentOn[
                                      "disabledCriteriaValue"
                                    ],
                                    required
                                  )
                                : false
                            }
                          >
                            <MenuItem selected value="">
                              <em>None</em>
                            </MenuItem>
                            {options?.map((item) => (
                              <MenuItem value={item?.value ?? item}>
                                {item?.label ?? item}
                              </MenuItem>
                            ))}
                            {queryKey &&
                              dropdownOptions[queryKey]?.map(formatOptions)}
                              {fieldName === "chargingProfilePk" && chargingProfileOptions.map(({charging_profile_pk: option, description: optionDescription}) => (
                                <MenuItem value={option} key={option}>
                                  {`${option} - ${optionDescription}`}
                                </MenuItem>
                              ))}
                          </Select>
                          {touched[fieldName] && (
                            <FormHelperText>{errors[fieldName]}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    );

                  case "DATE/TIME":
                    return (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth size="small">
                            <DateTimePicker
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  error={Boolean(
                                    (touched[fieldName] && errors[fieldName]) ||
                                      (touched[fieldName] &&
                                        dateTimeErr.fieldName === fieldName &&
                                        dateTimeErr.message)
                                  )}
                                  helperText={
                                    (dateTimeErr.fieldName === fieldName &&
                                    dateTimeErr.message
                                      ? dateTimeErr.message
                                      : "") ||
                                    (touched[fieldName] && errors[fieldName])
                                  }
                                />
                              )}
                              name={fieldName}
                              required={required}
                              label={label}
                              size="small"
                              PopperProps={{
                                placement: "auto",
                              }}
                              disablePast={operation==="GetDiagnostics"?false:true}
                              value={
                                values[fieldName] === ""
                                  ? null
                                  : values[fieldName]
                              }
                              onChange={(newVal) => {
                                setFieldValue(fieldName, newVal);
                              }}
                              minDateTime={operation==="GetDiagnostics"?null:allowedDateTime(
                                fieldName,
                                allFieldsArr
                              )}
                              onError={(error) => {
                                // console.log(error);
                                if (error) {
                                  setDateTimeErr({
                                    fieldName,
                                    message: "Incorrect time slot selected",
                                  });
                                } else setDateTimeErr({});
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </LocalizationProvider>
                    );
                  default:
                    return null;
                }
              }
            )}
          </Grid>
        </Card>
        <Grid container mt={4} justifyContent={"end"}>
          <Grid item xs={6} lg={6}>
            <Stack direction="row" spacing={2} justifyContent={"flex-end"} >
              {
                <>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting||isLoading}
                    loadingIndicator="Sending...."
                  >
                    Send Message
                  </LoadingButton>
                  <Button
                    size="large"
                    type="button"
                    variant="outlined"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </>
              }
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

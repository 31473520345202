import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  DialogContent,
  Dialog,
  Box,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { authService } from "src/services";
import Token from "../../../util/Token";
import enums from "src/util/enums";
import { authSlice } from "src/store/auth/auth.slice";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";
import { useMutation } from "@tanstack/react-query";
import { loginFormValidationSchema } from "src/util/validationSchemas";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { base64toBlob } from "src/util/convertToBase64";

const CustomPageLayer = ({ renderPageProps }) => {
  useEffect(() => {
    if (renderPageProps.textLayerRendered) {
      renderPageProps.markRendered(renderPageProps.pageIndex);
    }
  }, [renderPageProps.textLayerRendered]);

  return (
    <>
      {renderPageProps.svgLayer.children}
      {renderPageProps.textLayer.children}
      {renderPageProps.annotationLayer.children}
    </>
  );
};

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { mutate, isLoading, reset } = useMutation(authService.login);
  const { mutate: tnCMutate } = useMutation(authService.getuserTnCData);
  const { mutate: updateTnCmutate } = useMutation(authService.updateTnCForUser);
  const [tnCurl, setTnCurl] = useState();
  const [username, setUsername] = useState();
  const [pdfViewOpen, setPdfViewOpen] = useState(false);
  const [tnCcheckbox, setTnCcheckbox] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();

  const onLoginSuccess = (response) => {
    const loginResponse = response?.data?.data;
    if (loginResponse?.access_token) {
      Token.set(loginResponse.access_token);
      dispatch(authSlice.actions.setAuth(response?.data?.data?.user));
      navigate(enums.path.DASHBOARD_APP);
    } else {
      dispatch(
        alertSlice.actions.setAlert({
          severity: "error",
          open: true,
          message: "Error in login. Please try again.",
        })
      );
    }
  };

  const onLoginError = (error) => {
    dispatch(
      alertSlice.actions.setAlert({
        severity: "error",
        open: true,
        message: error?.response?.data?.message ?? error.message,
      })
    );
  };

  const onAcceptingTnC = () => {
    updateTnCmutate(
      { username: username },
      {
        onSuccess: () => {
          setPdfViewOpen(false);
          loginUser(userData, { setSubmitting });
        },
      }
    );
  };

  const loginUser = (loginData, { setSubmitting }) => {
    delete loginData.remember;
    mutate(
      {
        ...loginData,
        password: window.btoa(loginData.password),
      },
      {
        onSuccess: onLoginSuccess,
        onError: onLoginError,
        onSettled: () => reset(),
      }
    );
    setSubmitting(false);
  };

  const convertBase64String = (pdfBase64) => {
    const pdfBlob = base64toBlob(pdfBase64, "application/pdf");
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);

    setTnCurl(pdfBlobUrl);

    return () => {
      URL.revokeObjectURL(pdfBlobUrl);
    };
  };

  const onSubmit = (loginData, { setSubmitting }) => {
    setUserData(loginData);
    setUsername(loginData?.username);
    tnCMutate(loginData?.username, {
      onSuccess: (response) => {
        const data = response.data.data;
        convertBase64String(data.url);
        // setTnCurl(data.url);
        if (data.status === 1 || !data.url) {
          loginUser(loginData, { setSubmitting });
        } else {
          setPdfViewOpen(true);
        }
      },
      onError: (err) => {
        onLoginError(err);
        setSubmitting(false);
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      remember: true,
    },
    validationSchema: loginFormValidationSchema,
    onSubmit: onSubmit,
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Stack spacing={3} sx={{ gap: "1rem" }}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Username"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps("username")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            InputLabelProps={{ shrink: true }}
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Keep me logged in"
          />

          <Link variant="subtitle2" to="/reset" href="/reset">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting || isLoading}
        >
          Log in
        </LoadingButton>
      </Form>
      {pdfViewOpen && (
        <Dialog
          fullWidth={true}
          aria-labelledby="customized-dialog-title"
          open={pdfViewOpen}
          sx={{
            backgroundColor: "#0c0a0ad4",
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxWidth: "1020px", // Set your width here
              },
            },
          }}
        >
          <DialogContent sx={{ padding: 0 }}>
            <>
              <style>
                {`
                    .rpv-core__inner-page {
                        overflow: hidden !important;
                    }
                `}
              </style>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/es5/build/pdf.worker.js">
                <div style={{ height: "450px" }}>
                  <Viewer
                    fileUrl={tnCurl} // Use tnCurl for the actual file URL from the state
                    renderPage={(renderPageProps) => (
                      <CustomPageLayer renderPageProps={renderPageProps} />
                    )}
                  />
                </div>
              </Worker>
            </>
            <Box padding="0 20px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tnCcheckbox}
                    onChange={(e) => setTnCcheckbox(e.target.checked)}
                  />
                }
                label="I have read and agree with the above terms and conditionḍs"
              />
            </Box>
            <Stack
              mt={2}
              sx={{ display: "flex", flexDirection: "row", gap: "1rem", padding: "0 20px 20px" }}
            >
              <Button
                variant="contained"
                onClick={onAcceptingTnC}
                disabled={!tnCcheckbox}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setPdfViewOpen(false);
                  setSubmitting(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </FormikProvider>
  );
}

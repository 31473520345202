import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material"; 
import { LoadingButton } from "@mui/lab";
import { countries } from "src/data/countriesWithCode";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phone: Yup.string().required("Email is required"),
    companyName: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    GSTIN: Yup.string(),
    companyAddress: Yup.string().required("Address is required"),
    pincode: Yup.string().required("Pincode is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      companyName: "",
      GSTIN: "",
      companyAddress: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (registrationData, { setSubmitting, resetForm }) => {
      // console.log(registrationData);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <div style={{ color: "red" }}>{error}</div>
      <div style={{ color: "green" }}>{successMessage}</div>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            required
            label="First Name"
            {...getFieldProps("firstName")}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <TextField
            fullWidth
            required
            label="Last Name"
            {...getFieldProps("lastName")}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <TextField
            fullWidth
            required
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />
          <TextField
            fullWidth
            required
            autoComplete="phone"
            type="number"
            label="Phone Number"
            placeholder="Enter your phone number"
            {...getFieldProps("phone")}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <TextField
            fullWidth
            required
            autoComplete="company name"
            label="Company Name"
            placeholder="Enter the company name or individual name"
            {...getFieldProps("companyName")}
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

            <TextField
            fullWidth
            required
            autoComplete="GSTIN"
            label="GSTIN"
            placeholder="Enter the company GSTIN" 
            {...getFieldProps("GSTIN")}
            error={Boolean(touched.GSTIN&& errors.GSTIN)}
            helperText={touched.GSTIN && errors.GSTIN}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <TextField
            multiline
            rows={2}
            maxRows={4}
            fullWidth
            required
            autoComplete="Address"
            label="Address"
            placeholder="Enter the company address"
            {...getFieldProps("companyAddress")}
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <TextField
            fullWidth
            required
            autoComplete="pincode"
            label="Pincode"
            placeholder="Enter the pincode"
            {...getFieldProps("pincode")}
            error={Boolean(touched.pincode && errors.pincode)}
            helperText={touched.pincode && errors.pincode}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <TextField
            fullWidth
            required
            autoComplete="city"
            label="City"
            placeholder="City"
            {...getFieldProps("city")}
            error={Boolean(touched.city && errors.city)}
            helperText={touched.city && errors.city}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <TextField
            fullWidth
            required
            autoComplete="state"
            label="State"
            placeholder="State"
            {...getFieldProps("state")}
            error={Boolean(touched.state && errors.state)}
            helperText={touched.state && errors.state}
            FormHelperTextProps={{ sx: { ml: 0 } }}
          />

          <Autocomplete
            fullWidth
            id="country-select-demo"
            sx={{ width: 300 }}
            options={countries}
            autoHighlight
            {...getFieldProps("country")}
            error={Boolean(touched.country && errors.country)}
            helperText={touched.country && errors.country}
            FormHelperTextProps={{ sx: { ml: 0 } }}

            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
            
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
// Constants related to customer onboarding form fields
export const customerOnboardingFormFields = [
  { name: "name", label: "Name", type: "text", placeholder: "Enter full name", minWidth: 200, flex: 1 },
  { name: "phone", label: "Phone Number", type: "text", placeholder: "Enter phone number", minWidth: 180, flex: 1 },
  { name: "email", label: "Email", type: "email", placeholder: "Enter email", minWidth: 220, flex: 1.2 },
  { name: "vehicleVin", label: "Vehicle VIN", type: "text", placeholder: "Enter vehicle VIN", minWidth: 180, flex: 1 },
];

// Default form values for customer onboarding
export const customerOnboardingDefaultValues = {
  name: "",
  phone: "",
  email: "",
  vehicleVin: "",
};

// Submit button configuration for the form
export const customerOnboardingFormSubmitButton = {
  label: "Submit",
  variant: "contained",
  color: "primary",
};

// Grid layout configuration for the form
export const customerOnboardingGrid = {
  containerSpacing: 2,
  itemXs: 12,
  itemSm: 6,
};

// Search bar configuration for customer onboarding
export const customerOnboardingSearchbar = {
  placeholder: "Search Customers",
  searchFunc: (e) => e.target.value,  // Update this function as needed for your use case
};

// Table columns for customer onboarding
export const customerOnboardingTableColumns = () => [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "email",
    headerName: "Email ID",
    flex: 1.2,
    minWidth: 220,
    renderCell: ({ value, id }) => (
      <Link underline="none" component={RouterLink} to={`/customer-onboarding/${id}`}>{value}</Link>
    ),
  },
  {
    field: "vehicleVin",
    headerName: "Vehicle VIN",
    flex: 1,
    minWidth: 180,
  },
];

// Export CSV configuration for the customer onboarding list
export const customerOnboardingExportCSV = {
  rows: [],
  columns: customerOnboardingTableColumns(),
  csvMorphemes: "customerOnboarding_list",
};

// Example data for the customer onboarding table
export const customerOnboardingTable = {
  rows: [
    { id: 1, name: "John Doe", phone: "1234567890", email: "john@example.com", vehicleVin: "VIN12345" },
    { id: 2, name: "Jane Smith", phone: "9876543210", email: "jane@example.com", vehicleVin: "VIN54321" },
  ],
  columns: customerOnboardingTableColumns(),
};

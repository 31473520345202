import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import Page from "src/components/Page";
import { useAccess } from "src/hooks/useAccess.hook";
import enums, { FEATURE } from "src/util/enums";
import axios from "../../util/axios";
import {
  reportListColumn,
  reportStatusList,
  reportTabListToShow,
} from "./ReportTabList.constant";
import { ReactComponent as TotalEnergy } from "../../image/total_energy.svg";
import { ReactComponent as TotalRevenue } from "../../image/total_revenue.svg";
import { ReactComponent as TotalSession } from "../../image/total_session.svg";
import { styled } from "@mui/material/styles";
import {
  isValidTimeInterval,
} from "src/utils/formatTime";
import { format } from "date-fns";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import {
  getAllExistedTeam,
  reportExcelDownload,
  reportTabData,
  reportTabHeaderData,
} from "src/react-query/endPoints";
import { RootTabItemStyles } from "src/theme/overrides/Tabs";
import {
  DatePicker,
  TabContext,
  TabList,
} from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { downloadCSV } from "src/utils/convertAnddownloadCsv";
import { useNavigate } from "react-router-dom";
import { downloadExcel } from "src/utils/downloadExcel";
import { useAuth } from "src/hooks/useAuth.hook";
import { useList } from "src/hooks/useFetch.hook";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";

const BoxStyle = styled("div")(({ theme }) => ({
  width: "100%",
  border: "1px solid #00000036",
  display: "grid",
  gridTemplateRows: "20% 80%",
  placeItems: "center",
  padding: "1rem",
  borderRadius: "10px",
}));

const RootTabStyle = styled(TabList)(({ theme, length }) => ({
  width: "98%",
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const {
  actions: { setAlert },
} = alertSlice;

const ReportTabList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allTimeMenu = ["Last 7 days", "Last 30 days", "Last 90 days", "Custom"];
  const [isCustomSelect, setIsCustomSelect] = useState(false);
  const [timeInterval, setTimeInterval] = useState();
  const [tableData, setTableData] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [headerData, setHeaderData] = useState();
  const [getDate, setGetDate] = useState({ startDate: "", endDate: "" });
  const isReadOnly = useAccess(FEATURE.REPORT, "RP");
  const finalTableColumns = reportListColumn(isReadOnly);
  const { teamId } = useSelectedTeam();
  const [selectedTab, setSelectedTab] = useState("Statement Report Tab");
  const tabsToShow = [
    { value: "Statement Report Tab", label: "Statement Report Tab" },
  ];
  const reportTabListToShowData = reportTabListToShow();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const { user } = useAuth();
  const companyType = useMemo(() => {
    return user?.company?.companyType;
  }, [user]);
  const [allTeamsData, setAllTeamsData] = useState();
  const [dummyTeamData, setDummyTeamData] = useState();
  const [chargeBoxList, setChargeBoxList] = useState();
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [chargeBoxName, setChargeBoxName] = useState([]);
  const [payoutStatus, setPayoutStatus] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const datesForPicker = [
    { label: "Start Date", value: "start_date" },
    { label: "End Date", value: "end_date" },
  ];

  const rowOrCellClickRedirect = ({ id }) => {
    // if(isReadOnly) return;
    navigate(`/transactions/${id}`);
  };

  const errorNotification = () => {
    return dispatch(
      setAlert({
        open: true,
        message: "Please select a valid time interval",
        severity: enums.SEVERITY.ERROR,
      })
    );
  };

  const { data: chargers, isFetching: isChargerFetching } = useList("chargers");

  useEffect(() => {
    if (user?.company?.id === teamId) {
      setAllTeamsData(dummyTeamData);
    } else {
      const team = dummyTeamData?.filter(
        (list) => list.parentCompanyId === teamId
      );
      setAllTeamsData(team);
    }
  }, [teamId, dummyTeamData]);

  useEffect(() => {
    try {
      const getAllTeam = async () => {
        const response = await axios.get(getAllExistedTeam(teamId));
        setAllTeamsData(response.data.data);
        setDummyTeamData(response.data.data);
      };
      companyType === "GP" && getAllTeam();
    } catch (err) {
      console.log(err);
    }
  }, [companyType]);

  const handleChangeTimeInterval = (value) => {
    if (value === "Custom") {
      setIsCustomSelect(true);
      return;
    }
    setIsCustomSelect(false);
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let startDate = format(date, "yyyy-MM-dd HH:mm:ss");
    setTimeInterval(value);
    switch (value) {
      case "Today":
        {
          let endDate = new Date();
          setGetDate({
            startDate: startDate,
            endDate: format(endDate, "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      case "Yesterday":
        {
          let ystrDate = new Date().setDate(new Date().getDate() - 1);
          let actualDate = format(
            new Date(ystrDate).setHours(0, 0, 0, 0),
            "yyyy-MM-dd HH:mm:ss"
          );
          setGetDate({
            startDate: actualDate,
            endDate: format(
              new Date(actualDate).setHours(23, 59, 59),
              "yyyy-MM-dd HH:mm:ss"
            ),
          });
        }
        break;
      case "Last 7 days":
        {
          let endDate = new Date().setDate(new Date().getDate() - 7);
          setGetDate({
            startDate: format(
              new Date(endDate).setHours(0, 0, 0, 0),
              "yyyy-MM-dd HH:mm:ss"
            ),
            endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      case "Last 30 days":
        {
          let endDate = new Date().setDate(new Date().getDate() - 30);
          setGetDate({
            startDate: format(
              new Date(endDate).setHours(0, 0, 0, 0),
              "yyyy-MM-dd HH:mm:ss"
            ),
            endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      case "Last 90 days":
        {
          let endDate = new Date().setDate(new Date().getDate() - 90);
          setGetDate({
            startDate: format(
              new Date(endDate).setHours(0, 0, 0, 0),
              "yyyy-MM-dd HH:mm:ss"
            ),
            endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      default:
        break;
    }
  };

  const saveToExcel = async () => {
    try {
      let reportListPayload = {
        startDate: getDate?.startDate,
        endDate: getDate?.endDate,
        companyFilter: companyType === "P" ? [] : selectedCompany,
        teamfilter: companyType === "P" ? selectedCompany : selectedTeam,
        chargeBoxFilter: chargeBoxName,
        statusFilter: payoutStatus,
      };

      const response = await axios.post(
        reportExcelDownload(teamId),
        reportListPayload,
        {
          responseType: "blob",
        }
      );

      downloadExcel(response.data, "report");
    } catch (error) {
      console.error("Error downloading the Excel file", error);
    }
  };

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  };

  function convertArrayOfObjectsToCSV(arrayOfObjects) {
    if (
      !arrayOfObjects ||
      !Array.isArray(arrayOfObjects) ||
      arrayOfObjects.length === 0
    ) {
      return "";
    }
    const rows = arrayOfObjects.map((object) => {
      const values = Object.values(object).map((value) => `"${value}"`);
      return values.join("; ");
    });
    return rows.join("\n");
  }

  const handleExport = () => {
    const csv = convertArrayOfObjectsToCSV(tableData);
    downloadCSV(csv, "Transaction_Report");
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setPaginationModel({ page: newPage, pageSize: pageSize });
  };

  const handleChangeCompany = (e) => {
    let value = e.target.value
    setSelectedCompany(value);
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    if (companyType === "GP" && value.length > 0) {
      const teamFilterValue = dummyTeamData.filter((filter) =>
        value.includes(filter.parentCompanyId)
      );
      setAllTeamsData(teamFilterValue);
    } else {
      setAllTeamsData(dummyTeamData);
      setSelectedTeam([])
    }

    if (companyType === "P" && value.length > 0) {
      const chargeBoxFilter = chargers.filter((filter) =>
        value.includes(filter.teamId)
      );
      setChargeBoxList(chargeBoxFilter);
    } else {
      setChargeBoxList(chargers);
      setChargeBoxName([]);
    }
  };

  const handleChangeTeam = (e) => {
    let value = e.target.value
    setSelectedTeam(value);
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });

    if (value.length > 0) {
      const chargeBoxFilter = chargers.filter((filter) =>
        value.includes(filter.teamId)
      );
      setChargeBoxList(chargeBoxFilter);
    } else {
      setChargeBoxList(chargers);
      setChargeBoxName([]);
    }
  };

  const handleChangeChargeBox = (e) => {
    setChargeBoxName(e.target.value);
    if(!e.target.value.length > 0){
      setPayoutStatus([]);
    }
  };

  const handleChangeStatus = (e) => {
    setPayoutStatus(e.target.value);
  };

  useEffect(() => {
    const getReportData = async () => {
      try {
        let reportListPayload = {
          startDate: getDate?.startDate,
          endDate: getDate?.endDate,
          page,
          pageSize,
          companyFilter: companyType === "P" ? [] : selectedCompany,
          teamfilter: companyType === "P" ? selectedCompany : selectedTeam,
          chargeBoxFilter: chargeBoxName,
          statusFilter: payoutStatus,
        };

        const response = await axios.post(
          reportTabData(teamId),
          reportListPayload
        );
        if (response.data.data) {
          setIsFetching(false);
          setTableData(response.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (
      (selectedCompany?.length > 0 || user?.company?.id !== teamId) && 
      getDate?.startDate &&
      getDate?.endDate &&
      isValidTimeInterval({
        start: getDate.startDate,
        end: getDate.endDate,
      })
    ) {
      setTimeInterval(`${getDate.startDate} - ${getDate.endDate}`);
      if(selectedTeam?.length > 0 || user?.company?.id === teamId){
        getReportData();
      } else if(((companyType === "P" && user?.company?.id !== teamId) || companyType === "T") && chargeBoxName.length > 0){
        getReportData();
      }
    } else if (getDate?.startDate && getDate?.endDate && isValidTimeInterval({
      start: getDate.startDate,
      end: getDate.endDate,
    })) {
      errorNotification();
    }
  }, [
    getDate,
    page,
    pageSize,
    selectedCompany,
    selectedTeam,
    chargeBoxName,
    payoutStatus,
  ]);

  useEffect(() => {
    const getReportData = async () => {
      try {
        let headerListPayload = {
          startDate: getDate?.startDate,
          endDate: getDate?.endDate,
          companyFilter: companyType === "P" ? [] : selectedCompany,
          teamfilter: companyType === "P" ? selectedCompany : selectedTeam,
          chargeBoxFilter: chargeBoxName,
          statusFilter: payoutStatus,
        };

        const headerResponse = await axios.post(
          reportTabHeaderData(teamId),
          headerListPayload
        );
        if (headerResponse.data.data) {
          setHeaderData(headerResponse.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (
      (selectedCompany?.length > 0 || user?.company?.id !== teamId) &&
      getDate?.startDate &&
      getDate?.endDate &&
      isValidTimeInterval({
        start: getDate.startDate,
        end: getDate.endDate,
      })
    ) {
      if(selectedTeam?.length > 0 || user?.company?.id === teamId){
        getReportData();
      } else if(((companyType === "P" && user?.company?.id !== teamId) || companyType === "T") && chargeBoxName.length > 0){
        getReportData();
      }
    }
  }, [getDate, selectedCompany, selectedTeam, chargeBoxName, payoutStatus]);

  const onChangeDateRange = async (value, type) => {
    setIsFetching(true);
    if (type === "start_date") {
      const startDate = format(
        new Date(value).setHours(0, 0, 0, 0),
        "yyyy-MM-dd HH:mm:ss"
      );
      setGetDate((prev) => ({ ...prev, startDate: startDate }));
    } else {
      const endDate = format(
        new Date(value).setHours(23, 59, 59),
        "yyyy-MM-dd HH:mm:ss"
      );
      setGetDate((prev) => ({ ...prev, endDate: endDate }));
    }
  };

  return (
    <Page title="Report | Smart-CMS">
      <TabContext value={selectedTab}>
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <RootTabStyle
            indicatorColor="transparent"
            centered
            length={tabsToShow.length}
            onChange={handleTabChange}
          >
            {tabsToShow.map((tab, index) => (
              <RootTabItemStyles
                key={index}
                value={tab.value}
                label={tab.label}
              />
            ))}
          </RootTabStyle>
        </Stack>
        <Card sx={{ p: 4, overflow:'auto' }}>
          <Stack direction={{ xs: "column", sm: "row" }} // Column for mobile, row for larger screens
                sx={{
                  gap: { xs: "2rem", sm: "4rem" }, // Smaller gap for mobile, larger for windows
                }}>
            <BoxStyle>
              <Typography
                mt={2}
                mb={1}
                sx={{ fontWeight: "600", fontSize: { xs: "14px", sm: "16px" },}}
              >
                Total Energy
              </Typography>
              <Box sx={{ display: "flex", placeItems: "center", gap: "2rem" }}>
                <TotalEnergy />
                <Box sx={{ color: "#00AB55" }}>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontWeight: "600",
                      fontSize: "2rem",
                    }}
                  >
                    {headerData?.totalEnergy?.toFixed(2)}
                  </Typography>
                  <Typography sx={{ display: "inline-block" }}>KwH</Typography>
                </Box>
              </Box>
            </BoxStyle>
            <BoxStyle>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  placeItems: "center",
                }}
              >
                <Typography
                  mb={1}
                  mt={2}
                  sx={{ fontWeight: "600", fontSize: { xs: "14px", sm: "16px" }, }}
                >
                  Total Revenue
                </Typography>
                <Typography
                  mb={1}
                  sx={{ marginTop: "-10px", fontSize: { xs: "10px", sm: "12px" },  }}
                >
                  (CPO Payout)
                </Typography>
              </Box>
              <Box sx={{ display: "flex", placeItems: "center", gap: "2rem" }}>
                <TotalRevenue />
                <Typography
                  sx={{ color: "#00AB55", fontWeight: "600", fontSize: "2rem" }}
                >
                  {headerData?.totalRevenue?.toFixed(2)}
                </Typography>
              </Box>
            </BoxStyle>
            <BoxStyle>
              <Typography
                mb={1}
                mt={2}
                sx={{ fontWeight: "600", fontSize: "16px" }}
              >
                Total Session
              </Typography>
              <Box sx={{ display: "flex", placeItems: "center", gap: "2rem" }}>
                <TotalSession />
                <Typography
                  sx={{ color: "#00AB55", fontWeight: "600", fontSize: "2rem" }}
                >
                  {headerData?.totalCount}
                </Typography>
              </Box>
            </BoxStyle>
          </Stack>
          <Box>
            <Typography
              mt={4}
              sx={{
                borderBottom: "2px solid #00000036",
                paddingBottom: "12px",
                fontSize: { xs: "0.9rem", sm: "1rem" }, // Adjust font size for mobile
              }}
            >
              STATEMENT REPORT
            </Typography>
            <Grid container mt={4} mb={4} sx={{ gridRowGap: "2rem" }}>
              <>
                {(companyType === "GP" || companyType === "P") &&
                  user?.company?.id === teamId && (
                    <Grid item lg={3} md={4} xs={6}>
                      <FormControl size="small" sx={{ width: { xs: "95%", sm: "200px" } }}>
                        <InputLabel id="demo-simple-select-label"
                        sx={{ fontSize: { xs: "12px", sm: "16px" } }} 
                        >
                          {companyType === "GP"
                            ? "Select Company"
                            : "Select Team"}
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          value={selectedCompany}
                          onChange={(e) => handleChangeCompany(e)}
                          // input={<OutlinedInput label="Company Name" />}
                          MenuProps={MenuProps}
                          sx={{
                            width: "100%",
                            fontSize: { xs: "12px", sm: "14px" }, // Adjust select font size for mobile
                          }}
                        >
                          {user?.role
                            ?.filter(
                              (team) => team.teamId !== user?.company?.id
                            )
                            ?.map((team) => (
                              <MenuItem
                                key={team.teamId}
                                value={team.teamId}
                                sx={{
                                  pl: 4,
                                  fontSize: { xs: "12px", sm: "14px" }, // Adjust menu item font size for mobile
                                }}
                              >
                                {team.teamName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                {companyType === "GP" && (
                  <Grid lg={3} md={4} xs={6}>
                    <FormControl
                      size="small"
                      disabled={
                        selectedCompany.length > 0 ||
                        user?.company?.id !== teamId
                          ? false
                          : true
                      }
                      sx={{ width: { xs: "100%", sm: "200px" } }}
                    >
                      <InputLabel id="demo-multiple-name-label"
                       sx={{ fontSize: { xs: "12px", sm: "16px" } }}
                      >
                        Team Name
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedTeam}
                        onChange={(e) => handleChangeTeam(e)}
                        // input={<OutlinedInput label="Team Name" />}
                        MenuProps={MenuProps}
                        sx={{
                          width: "100%",
                          fontSize: { xs: "12px", sm: "14px" }, // Adjust select font size for mobile
                        }}
                      >
                        {allTeamsData?.length > 0 &&
                          allTeamsData.map((team) => (
                            <MenuItem
                              key={team.companyId}
                              value={team.companyId}
                              sx={{
                                pl: 4,
                                fontSize: { xs: "12px", sm: "14px" }, // Adjust menu item font size for mobile
                              }}
                            >
                              {team.companyName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
              <Grid lg={3} md={4} xs={6}>
                <FormControl
                  size="small"
                  disabled={
                    selectedTeam.length > 0 ||
                    (companyType === "P" && selectedCompany.length > 0) ||
                    (companyType === "P" && user?.company?.id !== teamId)
                      ? false
                      : true
                  }
                  sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                  <InputLabel id="demo-multiple-name-label"
                   sx={{ fontSize: { xs: "12px", sm: "16px" } }}
                  >
                    Charge Box Id
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={chargeBoxName}
                    onChange={(e) => handleChangeChargeBox(e)}
                    MenuProps={MenuProps}
                    sx={{
                      width: "95%",
                      fontSize: { xs: "12px", sm: "14px" }, // Adjust select font size for mobile
                    }}
                  >
                    {(companyType === "P" && user?.company?.id !== teamId) || companyType === "T" ? chargers?.map((charger) => (
                      <MenuItem
                        key={charger.chargepoint_pk}
                        value={charger.chargepoint_id}
                        sx={{
                          pl: 4,
                          fontSize: { xs: "12px", sm: "14px" }, // Adjust menu item font size for mobile
                        }}
                      >
                        {charger.chargepoint_id}
                      </MenuItem>
                    )) : chargeBoxList?.map((charger) => (
                      <MenuItem
                        key={charger.chargepoint_pk}
                        value={charger.chargepoint_id}
                        sx={{
                          pl: 4,
                          fontSize: { xs: "12px", sm: "14px" }, // Adjust menu item font size for mobile
                        }}
                      >
                        {charger.chargepoint_id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid lg={3} md={4} xs={6}>
                <FormControl size="small" disabled={!chargeBoxName.length > 0}
                  sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                  <InputLabel id="demo-multiple-name-label"
                  sx={{ fontSize: { xs: "12px", sm: "16px" } }}
                  >
                    Payout Status
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={payoutStatus}
                    onChange={(e) => handleChangeStatus(e)}
                    MenuProps={MenuProps}
                    sx={{
                      width: "100%",
                      fontSize: { xs: "12px", sm: "14px" }, // Adjust select font size for mobile
                    }}
                  >
                    {reportStatusList?.map((status) => (
                      <MenuItem
                        key={status}
                        value={status}
                        sx={{
                          pl: 4,
                          fontSize: { xs: "12px", sm: "14px" }, // Adjust menu item font size for mobile
                        }}
                      >
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid lg={3} md={4} xs={6}>
                <FormControl size="small" disabled={selectedCompany.length > 0 || selectedTeam.length > 0 || chargeBoxName.length > 0 ? false : true}
                   sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                  <InputLabel id="demo-simple-select-label"
                  sx={{ fontSize: { xs: "12px", sm: "16px" } }}
                  >
                    Date
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={timeInterval}
                    label="Date"
                    value={timeInterval}
                    onChange={(event) =>
                      handleChangeTimeInterval(event.target.value)
                    }
                    sx={{
                      width: { xs: "95%", sm: "100%" },
                      fontSize: { xs: "12px", sm: "14px" }, // Adjust select font size for mobile
                    }}
                  >
                    {allTimeMenu.map((data) => (
                      <MenuItem key={data} value={data} 
                      sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                      >
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid lg={6} md={6} xs={12}>
                <Box sx={{
                    display: "flex",
                    flexDirection: { xs: "row", sm: "row" }, // Row on mobile, column on larger screens
                    alignItems: "center",
                    gap: { xs: 1, sm: 4 }, // Adjust gap between elements for mobile and desktop
                  }}
                >
                  <Typography  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    whiteSpace: { xs: "nowrap", sm: "nowrap" }, // Prevent text wrapping on mobile
                  }}>Custom Range:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row", sm: "row" }, // Stack vertically for mobile, row for larger screens
                      gap: { xs: 0.4, sm: 4 }, // Adjust spacing between fields
                      width: { xs: "60%", sm: "auto" }, // Full width on mobile
                      fontSize:{ xs: "8px", sm: "14px" } // Adjust font size for mobile
                    }}
                  >
                  {datesForPicker.map((list) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} key={list.value}>
                      <Grid item xs={12} md={6}>
                        <DatePicker
                          disabled={!isCustomSelect}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              value={
                                list.value === "start_date" ? getDate.startDate : getDate.endDate
                              }
                              size="small"
                              error={false}
                              sx={{
                                width: { xs: "83px", sm: "140px" }, // Adjust width for mobile and desktop
                                "& .MuiInputBase-input": {
                                  fontSize: { xs: "12px", sm: "14px" }, // Adjust font size for the input text
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: { xs: "11px", sm: "14px" }, // Adjust font size for the label
                                },
                              }}
                            />

                          )}
                          inputFormat="yyyy-MM-dd"
                          label={list.label}
                          value={
                            list.value === "start_date"
                              ? getDate.startDate
                              : getDate.endDate
                          }
                          onChange={(val) => onChangeDateRange(val, list.value)}
                        />
                      </Grid>
                    </LocalizationProvider>
                  ))}
                  </Box>
                </Box>
              </Grid>
              {tableData?.length > 0 && (
                <Grid lg={3} md={4} xs={6}>
                  <Button
                    variant="contained"
                    onClick={saveToExcel}
                    sx={{ whiteSpace: "pre" }}
                  >
                    Export To Excel
                  </Button>
                </Grid>
              )}
              {/* </>
                )} */}
            </Grid>
            {/* {!tableData?.length > 0 && (
              <Stack>
                <Typography sx={{ color: "red" }}>
                  This team have no transaction for {timeInterval} session. So
                  no statement generated.
                </Typography>
              </Stack>
            )} */}
            {/* <CommonSearchbar
              placeholder="Search"
              searchFunc={(e) => handleSearching(e)}
            /> */}
            {tableData?.length > 0 && (
              <BaseTable
                isReadOnly={isReadOnly}
                rows={tableData ?? []}
                columns={finalTableColumns}
                loading={isFetching}
                getRowId={(row) => row.transactionId}
                pageSize={pageSize}
                showExportCsvBtn={false}
                rowOrCellClickRedirect={rowOrCellClickRedirect}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                totalRows={headerData?.totalCount || 0}
                serverRowFetcher={true}
                rowsPerPageOptions={[10, 30, 50, 100]}
                paginationModel={paginationModel}
                sx={{
                  ".MuiDataGrid-columnHeaderTitle": {
                    overflow: "hidden   !important",
                    lineHeight: "20px   !important",
                    whiteSpace: "normal  !important",
                  },
                }}
              />
            )}
          </Box>
        </Card>
      </TabContext>
    </Page>
  );
};

export default ReportTabList;

import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { TagActionMenu } from "./TagActionMenu";
import { TagChargerActionMenu } from "./TagChargerActionMenu";
import Tooltip from "@mui/material/Tooltip";

export const tagListColumns = (isReadOnly)=>([
  {
    field: "tag",
    headerName: "RFID Tag",
    flex: 1,
    renderCell: ({ value, id }) => (
      <>
      {isReadOnly?value:<Link underline="none" component={RouterLink} to={`/tag/${id}`}>
        {value}
      </Link>}
      </>
    ),
  },
  {
    field: "parenttag",
    headerName: "Parent Group",
    flex: 1,
  },
  {
    field: "expirydate",
    headerName: "Expiry Date/Time",
    flex: 1,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    )
  },
  {
    field: "in_transaction",
    headerName: "In Transactions",
    type: "boolean",
    flex: 1,
  },
  {
    field: "blocked",
    headerName: "Blocked",
    type: "boolean",
    flex: 1,
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: TagActionMenu,
  },
]);

export const tagChargersColumns = (refetch) => [
  {
    field: "charge_box_id",
    headerName: "Chargerbox ID",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "last_heartbeat_timestamp",
    headerName: "Last Heartbeat",
    type: "date",
    flex: 1,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    )
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: (props) => <TagChargerActionMenu {...props} refetch={refetch} />,
  },
];
export const tagFormChargersColumns = () => [
  {
    field: "charge_box_id",
    headerName: "Chargerbox ID",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "last_heartbeat_timestamp",
    headerName: "Last Heartbeat",
    type: "date",
    flex: 1,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    )
  },
  // {
  //   field: "Actions",
  //   width: 70,
  //   sortable: false,
  //   renderCell: (props) => <TagChargerActionMenu {...props} refetch={refetch} />,
  // },
];

export const tagUsersColumn = [
  { 
    field: "firstName",
    headerName: "First Name",
    flex: 1
  },
  { 
    field: "lastName",
    headerName: "Last Name",
    flex: 1
  },
  { 
    field: "email",
    headerName: "Email",
    flex: 1
  },
]

export const getTagDefaultValues = (teamId, userId) => ({
  tag: "",
  emailId: "",
  parenttag: "",
  expirydate: "",
  max_active_trans_count: 0,
  note: "",
  chargerpointId: 0,
  companyId: teamId,
  userId
});

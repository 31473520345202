import { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField, FormControl, Card, Grid, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { onboardcustomerValidationSchema } from "src/util/validationSchemas.js"; // Ensure schema validates these fields
import { alertSlice } from "src/store/alert/alert.slice";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";  // Assuming you're using React Query
import { fetchApi } from "src/react-query/fetchWithError"; // Your API calling function
import { queryKeys } from "src/react-query/queryKeys"; // For query keys (if needed)

export default function CustomerOnboardingForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isReadOnly, setIsReadOnly] = useState(false);

  // Mutation for saving customer data
  const { mutate, isLoading, isError, error } = useMutation(
    (customerData) => fetchApi(queryKeys["createCustomer"](), customerData), // Update with your actual API
    {
      onSuccess: () => {
        dispatch(
          alertSlice.actions.setAlert({
            open: true,
            message: "Customer details saved successfully",
            severity: "success",
          })
        );
        navigate("/customer-onboarding"); // Redirect after success
      },
      onError: (err) => {
        dispatch(
          alertSlice.actions.setAlert({
            open: true,
            message: `Error: ${err.message || "Something went wrong"}`,
            severity: "error",
          })
        );
      },
    }
  );

  const onSubmit = async (customerData, { setSubmitting }) => {
    if (!customerData.email && !customerData.phone) {
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Please specify either email or phone number.",
          severity: "error",
        })
      );
      setSubmitting(false);
      return;
    }

    // Prepare the request body
    const customerReqBody = {
      name: customerData.Name,
      email: customerData.email,
      phone: customerData.phone,
      vinNumber: customerData.vinNumber,
    };

    // Call mutate to trigger API request
    mutate(customerReqBody);

    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      Name: "",
      email: "",
      phone: "",
      vinNumber: "", 
    },
    validationSchema: onboardcustomerValidationSchema,
    onSubmit: onSubmit,
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
  } = formik;

  return (
    <Card sx={{ p: 2 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" id="customer-form" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: 'uppercase',
                  fontFamily: 'Public Sans, sans-serif',
                  mb: 2,
                }}
              >
                Customer Details
              </Typography>

              <Divider className="text-muted" />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Name"
                  size="small"
                  {...getFieldProps("Name")}
                  disabled={isReadOnly}
                  required
                  error={Boolean(touched.Name && errors.Name)}
                  helperText={touched.Name && errors.Name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Email"
                  size="small"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={isReadOnly}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Phone"
                  size="small"
                  {...getFieldProps("phone")}
                  disabled={isReadOnly}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={(touched.phone && errors.phone) || "Phone number must start with a country code, e.g., +919876543210"}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  label="VIN Number"
                  size="small"
                  {...getFieldProps("vinNumber")}
                  disabled={isReadOnly}
                  required
                  error={Boolean(touched.vinNumber && errors.vinNumber)}
                  helperText={touched.vinNumber && errors.vinNumber}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3 }}>
            {!isReadOnly && (
              <>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || isLoading}
                  disabled={isReadOnly}
                >
                  Save
                </LoadingButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="outlined"
                  onClick={() => navigate("src/components/customer/customer-onboarding")}
                >
                  Cancel
                </LoadingButton>
              </>
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}

import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export const ChargerLimitModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate("/profile?tab=license");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" gutterBottom>
          Charger Limit Exceeded
        </Typography>
        <Typography variant="body1" gutterBottom>
          You have reached the maximum allowed number of chargers. To add more,
          please upgrade your license. Would you like to proceed with the
          upgrade?
        </Typography>
        <Box mt={3} display="flex" justifyContent="center" gap={2}>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            OK
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

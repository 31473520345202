import { Link as RouterLink } from "react-router-dom";
import {
  yearFirstDateTimeFormat,
} from "src/utils/formatTime";
import { Link } from "@mui/material";
import { TeamActionMenu } from "./TeamActionMenu";
import { TeamMembersActionMenu } from "./TeamMembersActionMenu";
import Tooltip from "@mui/material/Tooltip";

export const teamsColumns =(isReadOnly)=>( [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "active",
    headerName: "Status",
    flex: 1,
    renderCell: ({ value }) => (
      <span
        style={{ color: value.toLowerCase() === "y" ? "#1fd51f" : "#ff1616" }}
      >
        {value.toLowerCase() === "y" ? "Active" : "Inactive"}
      </span>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1.2,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    )
  },
  {
    field: "creationTime",
    headerName: "Created On",
    type: "date",
    flex: 1,
    valueFormatter: ({ value }) => {
      return yearFirstDateTimeFormat(value);
    },
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: TeamActionMenu,
  },
]);

export const teamMembersColumns = (actionRequired,enableRedirection) => [
  {
    field: "username",
    headerName: "User Name",
    flex: 1,
    renderCell: ({ value, id }) => (
      <> 
      {enableRedirection?<Link underline="none" component={RouterLink} to={`/team/${id}`}>
        {value}
      </Link>:value}
       </>
    ),
  },
  {
    field: "firstname",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "accesstype",
    headerName: "Access Type",
    flex: 1,
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: TeamMembersActionMenu,
    hide: !actionRequired,
  },
];

export const teamsFormTabs = [
  { value: "general", label: "GENERAL" },
  { value: "payout accounts", label: "PAYOUT ACCOUNTS" },
  { value: "address", label: "ADDRESS" },
  {value:"members",label:"TEAM MEMBERS"},
  {value:"finance", label:"FINANCE CONFIGURATION"}
]

export const initialPayoutAccountObj = {
  business_name:"",
  business_type:"",
  beneficiary_name:"",       
  email:"",
  phone:"",
  account_no:"",
  account_type:"",
  ifsc_code:"",
  gst:"",
  payout_percentage:0,
  tax_percentage:0,
  creation:true,
  deleteBtn:true

}

export const initialFinanceConfigObj = {
  charger_payment_details:{
    monthly_payment: true,
    dc_charges_monthly: 349,
    ac_charges_monthly: 249,
    dc_charges_annual: "",
    ac_charges_annual: "",
    tax: 18,
    other_fixed_cost: "",
  },
  transaction_payment_details:{
    pg_charges_bearer:"CPO",
    payment_gateway_percent:2.36,
    tax:18
  },
  payout_details: {
    payout_rule:5,
    tax_bearer:"OAZA",
    tax:18,
    chargemiles_commission_in_percent:true,
    chargemiles_commission_percent:5,
    chargemiles_commission_cost:"",
  },
};


export const possibleBusinessTypes=[
  {label:"Individual",value:"individual"},
  {label:"Proprietorship",value:"proprietorship"},
  {label:"Partnership",value:"partnership"},
  {label:"Private Limited",value:"private_limited"},
  {label:"Public Limited",value:"public_limited"},
  {label:"Limited Liability Partnership",value:"llp"},
  {label:"NonGovernmental Organization",value:"ngo"},
  {label:"Trust",value:"trust"},
  {label:"Society",value:"society"},
  {label:"Not Yet Registered",value:"not_yet_registered"},
  {label:"Hindu Undivided Family",value:"huf"},
]

export const possiblePayoutRules=[
  {label:"Fixed Amount per month or % per Unit (whichever is higher)",value:1,disabled:true},
  {label:"Fixed Amount per month or fixed per Unit (whichever is higher)",value:2,disabled:true},
  {label:"Fixed Amount per month and % per Unit",value:3,disabled:true},
  {label:"Fixed Amount per month and fixed per Unit",value:4,disabled:true},
  {label:"% per Unit",value:5},
  {label:"fixed per Unit",value:6},
  {label:"Fixed Amount per month",value:7,disabled:true},
]

export const getTeamDefaultValues = () => ({
  name: "",
  active: "Y",
  description: "",
  payoutAccounts:[
    initialPayoutAccountObj
  ],
  financeConfig:initialFinanceConfigObj,
  locationLatitude: "",
  locationLongitude: "",
  street: "",
  address: {
    addressId: "",
    street: "",
    houseNumber: "",
    zipCode: "",
    city: "",
    country: "",
    lat: "",
    lng: "",
  },
  attachment: { url: "" }
});

// src/hooks/useCustomerOnboarding.js

import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "src/react-query/fetchWithError"; // assuming this is how you fetch data
import { queryKeys } from "src/react-query/queryKeys";

export const useCustomerOnboardingList = (enabled = true) => {
  const { data, isFetching, error } = useQuery(
    queryKeys.customers, // Assuming you have the query key setup properly
    fetchApi, {
      enabled, // ensures the query is enabled based on the passed prop
    }
  );

  return { data, isFetching, error };
};

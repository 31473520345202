import { useMemo, useCallback } from "react";
import deleteFill from "@iconify/icons-eva/trash-2-outline";
import { ActionsMenu } from "src/components/Actions/ActionsMenu";
import { useChargingProfileMutate } from "../ChargingProfile.hook";
import { newGetTeamUrl } from "src/react-query/endPoints";
import { useQueryClient } from "@tanstack/react-query";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";

const ChargingProfileActionMenu = ({ id }) => {
  const { teamId } = useSelectedTeam();
  const { mutate: chargingProfileMutate } =
    useChargingProfileMutate("chargingProfile");
  const queryClient = useQueryClient();
  const deleteItem = useCallback(() => {
    const profileMutateObject = {
      url: newGetTeamUrl(teamId, id),
      method: "DELETE",
      body: id,
    };
    chargingProfileMutate(profileMutateObject, {
      onSuccess: () => queryClient.invalidateQueries("getChargingProfileList"),
    });
  }, [queryClient, chargingProfileMutate, id]);

  const actionList = useMemo(
    () => [
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: deleteItem,
      },
    ],
    [deleteItem]
  );

  return <ActionsMenu menuList={actionList} />;
};

export default ChargingProfileActionMenu;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { fetchApi, fetchWithError } from "src/react-query/fetchWithError";
import { queryKeys } from "src/react-query/queryKeys";
import { alertSlice } from "src/store/alert/alert.slice";
import { selector } from "src/react-query/select";
import { is } from "src/util/is";
import { useSelectedTeam } from "./useSelectedTeam";

const updateChargerList = (chargers) => {
  const updatedChargerList = chargers.reduce((updatedChargerList, charger) => {
    let status = "Inactive";
    if (charger.status === "A") {
      status = "Active";
    }

    const updatedCharger = { ...charger, status };
    updatedChargerList = [...updatedChargerList, updatedCharger];
    return updatedChargerList;
  }, []);
  return updatedChargerList;
};

export const useList = (queryKey, enabled, ...args) => {
  const { teamId } = useSelectedTeam();
  const { data, isFetching } = useQuery(
    !args.length ? queryKeys[queryKey](teamId) : queryKeys[queryKey](args),
    fetchWithError,
    {
      enabled: enabled ?? !is.empty(teamId),
      select: selector,
    }
  );

  if (queryKey === "chargers" && !is.empty(data)) {
    const updatedChargerList = updateChargerList(data);
    return { data: updatedChargerList, isFetching };
  }
  return { data, isFetching };
};

export const useGetDetail = (queryKey, { id } = {}) => {
  const { teamId } = useSelectedTeam();
  const enabledCriteria =
    queryKey === "availableTags"
      ? !is.empty(teamId)
      : !is.empty(teamId) && !is.empty(id);
  return useQuery(queryKeys[queryKey](teamId, id), fetchWithError, {
    enabled: enabledCriteria,
    select: selector,
  });
};

export const useGetSettlementPercentage = (isCreateTeam) => {
  return useQuery(queryKeys["settlementPercentage"], fetchWithError, {
    enabled: !!isCreateTeam,
    select: selector,
  });
};

export const useGetPaymentDetailsForPriceGroup = (isCreatePriceGroup,teamId) => {
  return useQuery(queryKeys["paymentDetailsForPriceGroup"](teamId), fetchWithError, {
    enabled: !!isCreatePriceGroup,
    select: selector,
  });
};
export const usePaymentDetailsOfCompany = (hasFinanceConfigAccess) => {
  const { teamId } = useSelectedTeam();

  return useQuery(queryKeys["paymentDetailsOfCompany"](teamId), fetchWithError, {
    enabled: !!hasFinanceConfigAccess,
    select: selector,
  });
};

export const useGetPaymentGatewayList =  () => {
  const mutationResult = useMutation(fetchApi);
  return mutationResult;
};

export const useGetMeterValues = (
  queryKey,
  { showMeterValues, transactionId, connectorId } = {}
) => {
  const { teamId } = useSelectedTeam();
  return useQuery(
    queryKeys[queryKey](teamId, transactionId, connectorId),
    fetchWithError,
    {
      enabled: showMeterValues && !is.empty(teamId) && !is.empty(transactionId),
      select: selector,
    }
  );
};
export const useMutate = (queryKey, { id } = {}, reset = true) => {
  const queryClient = useQueryClient();
  const { teamId } = useSelectedTeam();
  const dispatch = useDispatch();
  const mutationResult = useMutation(fetchApi, {
    onSuccess: (data) => {
      if (queryKey !== "ChargerVisibility" && queryKey !== "payoutBulkUpd") {
        queryClient.invalidateQueries(queryKeys[queryKey](teamId, id));
      }
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: data?.message,
          severity: "success",
        })
      );
    },
    onError: (error) => {
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: error?.response?.data?.message ?? error?.message,
          severity: "error",
        })
      );
    },
    onSettled: () => {
      reset && mutationResult.reset();
    },
  });
  return mutationResult;
};

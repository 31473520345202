import { memo, useRef, useState } from "react";

// mui components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";
import { useMutation } from "@tanstack/react-query";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
// constants, data and custom components
import Map from "./Map";
import { issueImgUpload } from "src/components/ServiceRequest/serviceRequest.hook";
import { fileToBase64 } from "src/utils/fileConvertBase64";
import { AMENITIES } from "../../components/charger/charger.constants";
import { is } from "src/util/is";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Address({ touched,errors, getFieldProps, setFieldValue, formValues, hide = false,notDisplayTitle=false,displayedFor }) {
  const imageUploadInputRef = useRef();
  const imgUpload = useMutation(issueImgUpload);
  const [imageUploadPending, setImageUploadPending] = useState(false);
  const handlePlacesChanged = (places) => {
    const {
      address_components,
      geometry: { location },
      formatted_address,
    } = places[0];
    if(address_components){
      for (let component of address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "country":
            setFieldValue("country", component.short_name);
            break;
          case "locality":
            setFieldValue("city", component.long_name);
            break;
          case "administrative_area_level_1":
            setFieldValue("state", component.long_name);
            break;
          case "postal_code":
            setFieldValue("zipCode", component.long_name);
            break;
          default:
        }
      }
      setFieldValue("street", formatted_address);
      setFieldValue("locationLatitude", location.lat());
      setFieldValue("locationLongitude", location.lng());
    }
  };
  const handleAmenitiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue("amenities",
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    ); 
  }

  const handleImageUpload = async (e) => {
    const files = e.target.files;
    const base64 = [];
    setImageUploadPending(!0);
    for(let file of files) {
      base64.push(await fileToBase64(file));
    }

    base64.forEach((b) => {
      imgUpload.mutate(
        {
          image: b,
        },
        {
          onSuccess: (response) => {
            // console.log(response)
            setImageUploadPending(false);
            // const updatedAttachmentObj = {
            //   ...values.attachment,
            //   url: response.data.data,
            // };
            const photos = formValues.photos.concat([response.data.data]);
            setFieldValue("photos", photos);
            // setUploadedImageUrl(response.data.data);
          },
        }
      );
    })
  };

  return (
    <Grid container spacing={3} className="mb-5">
      {!notDisplayTitle &&<Grid item xs={12} lg={12}>
        <Typography
          variant="subtitle1"
          gutterBottom
        >
          Address
        </Typography>
        <Divider />
      </Grid>}
      <Grid item xs={12} lg={6}>
        <Map
          onPlaces={handlePlacesChanged}
          lat={formValues.locationLatitude}
          lng={formValues.locationLongitude}
          address={formValues.street}
        />
      </Grid>
      {!hide && (
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Latitude"
                  size="small"
                  disabled={!0}
                  {...getFieldProps("locationLatitude")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Longitude"
                  size="small"
                  disabled={!0}
                  {...getFieldProps("locationLongitude")}
                />
              </FormControl>
            </Grid>
           {displayedFor==="charger" &&<> <Grid item xs={12} lg={12}>
                <InputLabel id="demo-multiple-chip-label">Amenities</InputLabel>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={formValues.amenities && formValues.amenities.length?formValues.amenities.filter((amenity)=>!is.empty(amenity)):[]}
                  onChange={handleAmenitiesChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {AMENITIES.map((am) => (
                    <MenuItem
                      key={am.value}
                      value={am.value}
                      // style={getStyles(name, personName, theme)}
                    >
                      {am.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box style={{ border: "2px solid #C4C4C4", borderRadius: 4, padding: 24 }}>
                <Stack direction="column" alignItems="center" gap={3}>
                  <Typography color="rgba(0, 0, 0, 0.60)" textAlign="center" variant="body2">You're encouraged to add a photo. It will help other users to have a better idea of the place.</Typography>
                  <Icon icon="tabler:camera-plus" width={71} height={55} color="#d9d9d9" hFlip={true} />
                  <LoadingButton
                    variant="contained"
                    onClick={() => imageUploadInputRef.current.click()}
                    loading={imageUploadPending}
                    loadingIndicator="Loading…"
                    startIcon={<Icon icon="ic:baseline-plus"></Icon>}
                  >
                    Upload
                    <input
                      accept="image/jpeg,image/png,image/jpg"
                      type="file"
                      hidden
                      multiple
                      id="issue-image"
                      ref={imageUploadInputRef}
                      onChange={handleImageUpload}
                    />
                  </LoadingButton>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              {  formValues.photos && 
              <ImageList cols={3}>
                { !is.empty(formValues.photos) && formValues.photos.filter(url=>!is.empty(url)).map((url) => (
                  <ImageListItem key={`${url}-key`}>
                    <img
                      src={url}
                      alt={"attachment"}
                      style={{
                        maxHeight: "170px",
                        maxWidth: "250px",
                      }}
                      loading="eager"
                    />
                </ImageListItem>
                ))}
              </ImageList>
          }
            </Grid>
            </>
            }
          </Grid>
        </Grid>
      )}
      {["charger"].includes(displayedFor) && <>
      <Grid item xs={12} lg={6}>
        <FormControl fullWidth size="small">
          <TextField
            type="number"
            label="Operator Number 1"
            size="small"
            {...getFieldProps("operator_number1")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormControl fullWidth size="small">
          <TextField
            type="number"
            label="Operator Number 2"
            size="small"
            {...getFieldProps("operator_number2")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormControl fullWidth size="small">
          <TextField
            type="email"
            label="Operator Email"
            size="small"
            {...getFieldProps("operator_email")}
            error={Boolean(touched.operator_email && errors.operator_email)}
            helperText={touched.operator_email && errors.operator_email}
          />
        </FormControl>
      </Grid></>}
    </Grid>
  );
}

export default memo(Address);

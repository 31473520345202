import { Card } from "@mui/material";
import { useList } from "src/hooks/useFetch.hook";
import { BaseTable } from "../BaseTable/BaseTable";
import { useSelector, useDispatch } from "react-redux";
import { tagListColumns } from "./Tags.constants";
import Page from "../Page";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import TagAttachChargerModal from "./TagAttachChargerModal";
import { dialogSlice } from "src/store/dailog/dailog.slice";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export const TagList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const hasCreateAccess = useAccess(FEATURE.TAG, "C");
  const isReadOnly = useAccess(FEATURE.TAG, "R");
  const { data: tags, isFetching } = useList("tags");
  const { open, data } = useSelector(({ dialog }) => dialog);
  const handleClose = () => dispatch(dialogSlice.actions.setDialog(false));
  const [tableData, setTableData] = useState();
  const rowOrCellClickRedirect = ({ id }) => {
    if (isReadOnly) return;
    navigate(`/tag/${id}`);
  };

  useEffect(() => {
    if (!isFetching) {
      // console.log(tags);
      let data = [...tags];
      setTableData(data);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = tags;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "tag",
            "parenttag",
            "expirydate",
            "in_transaction",
            "blocked",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "expirydate"
                ? yearFirstDateTimeFormat(value).toString()
                : value ? value.toString().toLowerCase() : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      // console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...tags]);
  };

  return (
    <Page
      title="RFID Tags | Smart-CMS"
      {...(hasCreateAccess && { path: `/tag/new` })}
      actionButtonText="Add New RFID Tag"
    >
      <Card sx={{ p: 2 }}>
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
        <BaseTable
          rows={tableData ?? []}
          isLoading={isFetching}
          columns={tagListColumns(isReadOnly)}
          showExportCsvBtn={true}
          csvMorphemes="auth_code"
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          pageSize={10}
          isReadOnly={isReadOnly}
          csvColumns={tagListColumns(isReadOnly)}
        />
      </Card>
      <TagAttachChargerModal open={open} data={data} onClose={handleClose} />
    </Page>
  );
};

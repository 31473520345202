import React, { useMemo } from 'react'
import { Box, Button,Tooltip } from '@mui/material'
import downloadFill from "@iconify/icons-eva/download-fill";
import { getIcon } from 'src/utils/utility';
import { saveToExcel } from 'src/utils/convertAnddownloadCsv';
import { yearFirstDateTimeFormat } from 'src/utils/formatTime';


export function isDateTimeField(fieldName) {
    return (/(date|time|createdAt|createAt|createdOn|createOn|updateAt|updatedAt|updateOn|updatedOn)/gmi).test(fieldName)
}

const ExportCSV = ({ rows, columns, csvMorphemes }) => {

    const csvObject = useMemo(() => {
        let csvObject = {};
        csvObject["headers"] = columns?.map((column) => column.headerName);
        csvObject["properties"] = columns?.map((column) => column.field)
        csvObject["valuesObjArr"] = rows?.map((row) => {
            let rowValue = { ...row }
            columns?.forEach((column) => {
                //DONE AS TO FORMAT FROM UTC TO LOCAL TIME FORMAT
                if (isDateTimeField(column.field)) {
                    rowValue = { ...rowValue, [column.field]: yearFirstDateTimeFormat(row[column.field]) }
                }
                //DONE AS THE VALUES COME IN BINARY FORM
                if (csvMorphemes === "auth_code" && (column.field === "blocked" || column.field === "in_transaction")) {
                    rowValue = { ...rowValue, [column.field]: !!(row[column.field]) }
                }
                //DONE AS FULL NAME COMES IN 2 PARTS FROM BACKEND
                if (csvMorphemes === "ev_drivers" && column.field === "fullName") {
                    rowValue = { ...rowValue, [column.field]: `${row.firstName} ${row.lastName}` }
                }
            });
            return rowValue;
        });
        return csvObject;
    }, [rows, columns, csvMorphemes]);

    const handleExport = () => {
        // const csvData = changeToCSV(csvObject);
        // downloadCSV(csvData, csvMorphemes);
        saveToExcel(csvObject, csvMorphemes)
    }

    return (
        <Box 
            sx={{
                display: "inline-block", 
                position: "absolute", 
                right: "18px", 
                top: "18px",
               
            }}
        >
            <Tooltip title="Export To Excel" arrow placement="top">
                <Button
                variant="contained"
                startIcon={getIcon(downloadFill)}
                onClick={handleExport}
                sx={{
                    px: { xs:1, sm: 2 }, // Adjust padding for mobile and larger screens
                    pl:{xs:"14px"},
                    pr:{xs:"4px"},
                    minWidth: { xs: "auto", sm: "inherit" }, // Shrink button for mobile
                }}
                >
                <Box 
                    component="span" 
                    sx={{
                    display: { xs: "none", sm: "inline" } // Hide text on small screens
                    }}
                >
                    Export To Excel
                </Box>
                </Button>
            </Tooltip>
        </Box>
    )
}

export default ExportCSV
import { Card } from "@mui/material";
import Page from "../Page";
import { FEATURE } from "src/util/enums";
import { useAccess } from "src/hooks/useAccess.hook";
import { useUsers } from "src/hooks/useUsers.hook";
import { BaseTable } from "../BaseTable/BaseTable";
import { userListColumns } from "./user.constants";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export default function UserList() {
  const navigate = useNavigate()
  const hasCreateAccess = useAccess(FEATURE.USERS, "C");
  const isReadOnly = useAccess(FEATURE.USERS, "R");
  const { data: users, isFetching } = useUsers();
  const [tableData, setTableData] = useState();
  const rowOrCellClickRedirect = ({ id }) => {
    if (isReadOnly) return;
    navigate(`/users/${id}`);
  };

  useEffect(() => {
    if (!isFetching) {
      let data = [...users];
      setTableData(data);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = users;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "username",
            "firstName",
            "lastName",
            "email",
            "createDate",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "createDate"
                ? yearFirstDateTimeFormat(value).toString()
                : value ? value.toString().toLowerCase() : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      // console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...users]);
  };

  return (
    <Page
      title="Users | Smart-CMS"
      {...(hasCreateAccess && { path: `/users/new` })}
      actionButtonText="Add CMS System User"
    >
      <Card sx={{ p: 2 }}>
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
        <BaseTable
          rows={tableData ?? []}
          columns={userListColumns(isReadOnly)}
          loading={isFetching}
          getRowId={(row) => row.userId}
          showExportCsvBtn={true}
          csvMorphemes="cms_system_users"
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          isReadOnly={isReadOnly}
          csvColumns={userListColumns(isReadOnly)}
        />
      </Card>
    </Page>
  );
}

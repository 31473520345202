import { Card, Stack } from "@mui/material";
import Page from "src/components/Page.js";
import { FEATURE } from "src/util/enums";
import { useAccess } from "src/hooks/useAccess.hook";
import { useCustomerOnboardingList } from "src/hooks/useCustomerOnboarding"; // Updated hook
import { BaseTable } from "src/components/BaseTable/BaseTable.js";
import { customerOnboardingTableColumns } from "src/components/customer/customer-onboarding/customeronboard.constant.js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import ExportCSV from "src/components/ExportCsv/ExportCSV";
import { alertSlice } from "src/store/alert/alert.slice"; // Alert slice for error handling

export default function CustomerOnboardingList() {
  const { data: customers, isFetching, error } = useCustomerOnboardingList(true); // Using updated hook for fetching customers
  const isReadOnly = useAccess(FEATURE.CUSTOMER_ONBOARDING.title, "R");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (isFetching) return; // Avoid updates if data is still being fetched

    if (error) {
      // Handle error case if fetching fails
      console.error("Error fetching customer data:", error);
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: "Error fetching customer data.",
          severity: "error",
        })
      );
      return;
    }

    if (customers) {
      setTableData(customers); // Update table data with fetched customers
    }
  }, [isFetching, customers, error, dispatch]);

  const handleSearching = (event) => {
    const searchValue = event.target.value.trim().toLowerCase();
    const filteredData = searchValue
      ? customers.filter((customer) =>
          ["name", "phone", "email", "vehicleVin"]
            .map((key) => customer[key]?.toString().toLowerCase())
            .some((value) => value.includes(searchValue))
        )
      : customers;

    setTableData(filteredData);
  };

  return (
    <Page
      title="Customer Onboarding | Smart-CMS"
      path="/customer-onboarding/new"
      actionButtonText="Add Customer"
      action={() =>
        dispatch({
          type: "OPEN_NEW_CUSTOMER_MODAL", // Example action
          payload: { tabOpened: "general" },
        })
      }
    >
      <Card sx={{ p: 2 }}>
        {/* Search Bar & Export Button in a single row */}
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <CommonSearchbar placeholder="Search" searchFunc={handleSearching} />
          <ExportCSV
            rows={tableData}
            columns={customerOnboardingTableColumns(isReadOnly)}
            csvMorphemes="customer_onboarding"
          />
        </Stack>

        {/* Table */}
        <BaseTable
          rows={tableData || []} // Ensure rows are never undefined
          columns={customerOnboardingTableColumns(isReadOnly)}
          loading={isFetching}
          getRowId={(row) => row.id}
          pageSize={10}
          showExportCsvBtn={false} // Export handled separately
          rowOrCellClickRedirect={({ id }) => navigate(`/customer-onboarding/${id}`)}
          isReadOnly={isReadOnly}
        />
      </Card>
    </Page>
  );
}
